import React from 'react'

const DR = ({ dataEmployeeFiltered, dataPerformance }) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des']
  const dataLabel = ['No.', 'NIK', 'Nama', ...months, 'Average']

  const groupPerformanceByEmployeeAndMonth = (nik, month) => {
    const performanceData = dataPerformance.filter(
      (performance) =>
        performance.nik === nik &&
        new Date(performance.periode).getMonth() === month &&
        performance.category.toLowerCase() === 'dr'
    )
    return performanceData.length > 0
      ? performanceData.reduce((total, performance) => total + Number(performance.value), 0)
      : '-'
  }

  return (
    <table className="table table-bordered border-dark">
      <thead className="table-info table-bordered border-dark sticky-header">
        <tr>
          {dataLabel.map((label, i) => (
            <th key={i} className="text-center fw-bold">
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataEmployeeFiltered.map((items, j) => {
          const monthlyPerformance = months.map((_, monthIndex) =>
            groupPerformanceByEmployeeAndMonth(items.nik, monthIndex)
          )

          const validPerformances = monthlyPerformance.filter((value) => value !== '-')

          const totalMonthsWithData = validPerformances.length
          const averagePerformance =
            totalMonthsWithData > 0
              ? (
                validPerformances.reduce((total, monthTotal) => total + monthTotal, 0) /
                totalMonthsWithData
              ).toFixed(2)
              : '-'

          return (
            <tr className="text-center" key={j}>
              <td>{j + 1}.</td>
              <td>{items.nik}</td>
              <td className="text-start">{items.nama_lengkap}</td>
              {monthlyPerformance.map((total, k) => (
                <td key={k}>{total !== '-' ? total : '-'}</td>
              ))}
              <td className="fw-bold">{averagePerformance !== '-' ? `${averagePerformance} %` : '-'}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default DR
