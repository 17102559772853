import React from 'react'

const Notes = ({ dataNotesByNik }) => {

  return (
    <div className='container card mt-2 animate__animated animate__fadeInDown'>
      <div className='title-h1 fs-1 text-center'>Catatan</div>
      <table className='table table-bordered border-dark table-hover'>
        <thead className='table-info table-bordered border-dark'>
          <tr className='text-center fw-bold fs-4'>
            <th>No.</th>
            <th>Jenis</th>
            <th>Catatan</th>
          </tr>
        </thead>
        <tbody>
          {dataNotesByNik.map((item, i) => (
            <tr key={i}>
              <td className='fw-bold text-center'>{i + 1}.</td>
              <td className='text-center w-25'>{item.type}</td>
              <td className='w-75'>{item.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Notes