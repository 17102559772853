import axios from "axios"

export const deleteVacation = (id_letter) => {

  if (window.confirm("Apakah anda yakin akan menghapus data cuti!"))
    axios.delete(`${process.env.REACT_APP_URL}/vacation`, { data: id_letter })
      .then(() => {
        alert("Berhasil menghapus data cuti")
        window.location.reload()
      })
      .catch((err) => {
        alert("Gagal menghapus data cuti")
        console.log('eror', err)
      })
}