import React from 'react'
import { months } from '../../Components/Utility'

const Kpi = ({ filterDataPerformanceByNik }) => {

  const dataKpiByMonth = months.map((date) => {
    const data = filterDataPerformanceByNik('kpi', date)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const validValues = dataKpiByMonth.filter(value => value !== '-').map(value => parseFloat(value))
  const total = validValues.reduce((acc, value) => acc + value, 0)
  const average = (validValues.length > 0 ? (total / validValues.length) : 0).toFixed(2)

  return (
    <tbody>
      <tr className='text-center'>
        <td className='fw-bold'>KPI</td>
        {dataKpiByMonth.map((value, i) => (
          <td key={i}
            style={{
              color: value !== '-' && value <= 85 ? 'red' : 'black',
              fontWeight: value !== '-' && value <= 85 ? 'bold' : 'normal',
            }}>
            {value !== '-' ? `${value}` : value}
          </td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{average > 0.00 ? `${average} %` : '-'}</td>
      </tr>
    </tbody>
  )
}

export default Kpi
