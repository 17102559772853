import axios from "axios"

export const isValueEmpty = (value) => {
  return !value || value === ""
}

export const logout = async () => {
  const confirmed = window.confirm('Apakah Anda yakin ingin logout?');

  if (confirmed) {
    axios.delete(`${process.env.REACT_APP_URL}/users/logout`, { withCredentials: true })
      .catch((err) => console.log('err', err.response.data.msg))
    window.location = "/";
  }

}

export const dateFormated = (date) => {
  const isDate = new Date(date)
  const isDateFormated = `${isDate.getDate()} ${isDate.toLocaleDateString('default', { month: "short" })} ${isDate.getFullYear()}`
  return isDateFormated
}

export const changeDurationMonth = (1000 * 60 * 60 * 24 * 30.44)

export const formatTimeRemaining = (timeRemaining) => {
  if (timeRemaining < 0) return "Kontrak Telah Berakhir";
  timeRemaining %= (1000 * 60 * 60 * 24 * 365);
  const changeDurationMonth = 1000 * 60 * 60 * 24 * 30;
  const months = Math.floor(timeRemaining / changeDurationMonth);
  timeRemaining %= changeDurationMonth;
  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

  return months === 0 ? `${days} hari` : `${months} bulan, ${days} hari`;
}

export const calculateDuration = (startDate, endDate, changeDurationMonth) => {
  const duration = Math.round((new Date(endDate) - new Date(startDate)) / changeDurationMonth)
  const validDuration = isNaN(duration) ? 0 : duration
  return validDuration === 0 ? "0" : `${validDuration}`;
}

export const calculateExpireToken = (exp) => {
  const now = Date.now() / 1000;
  const remainingSecond = exp - now;

  return remainingSecond > 0 ? remainingSecond / 3600 : 0;
}

export const countAge = (date_born) => {
  const today = new Date()
  const dateOfBorn = new Date(date_born)
  let age = today.getFullYear() - dateOfBorn.getFullYear()
  const monthAge = today.getMonth() - dateOfBorn.getMonth()
  age = (monthAge < 0 || (monthAge === 0 && today.getDate() < dateOfBorn.getDate())) ? age - 1 : age;
  return age
}

export const generationCategory = (date_of_born) => {
  const dateOfBorn = new Date(date_of_born)
  const yearBorn = dateOfBorn.getFullYear()
  const generation =
    yearBorn >= 1946 && yearBorn <= 1964 ? 'Baby Boomer' :
      yearBorn >= 1965 && yearBorn <= 1980 ? 'Generasi X' :
        yearBorn >= 1981 && yearBorn <= 1996 ? 'Millennial (Y)' :
          yearBorn >= 1997 && yearBorn <= 2012 ? 'Generasi Z' :
            yearBorn >= 2013 && yearBorn <= 2024 ? 'Generasi A' : 'Unknown Generasi';
  return generation
}

export const months = [
  '2024-01-01',
  '2024-02-01',
  '2024-03-01',
  '2024-04-01',
  '2024-05-01',
  '2024-06-01',
  '2024-07-01',
  '2024-08-01',
  '2024-09-01',
  '2024-10-01',
  '2024-11-01',
  '2024-12-01'
]

export const getShortMonth = (dates) => {
  const date = new Date(dates)
  const month = date.toLocaleString('default', { month: 'short' })
  return month;
}

export const getRemainingTimeLicense = (date) => {
  if (!date) return '-'
  const now = new Date()
  const targetDate = new Date(date)
  const totalDayRemaining = Math.floor((targetDate - now) / (1000 * 60 * 60 * 24))

  if (totalDayRemaining < 0) return 'SIM tidak berlaku'

  const years = Math.floor(totalDayRemaining / 365)
  const months = Math.floor((totalDayRemaining % 365) / 30)
  const days = totalDayRemaining % 30

  let remainingDays;
  if (years > 0) {
    remainingDays = `${years} tahun`
  } else if (months > 0) {
    remainingDays = `${months} bulan`
  } else {
    remainingDays = `${days} hari`
  }

  return remainingDays
}

export const getRemainingTimeReminder = (date) => {
  if (!date) return '-'
  const now = new Date()
  now.setHours(0, 0, 0, 0);
  const targetDate = new Date(date)
  targetDate.setHours(0, 0, 0, 0);
  const totalDayRemaining = Math.floor((targetDate - now) / (1000 * 60 * 60 * 24))

  if (totalDayRemaining < 0) return 'Tanggal sudah lewat';
  if (totalDayRemaining === 0) return 'Hari ini';
  if (totalDayRemaining === 1) return 'Besok';

  const years = Math.floor(totalDayRemaining / 365)
  const months = Math.floor((totalDayRemaining % 365) / 30)
  const days = totalDayRemaining % 30

  let remainingDays;
  if (years > 0) {
    remainingDays = `${years} tahun lagi`
  } else if (months > 0) {
    remainingDays = `${months} bulan lagi`
  } else {
    remainingDays = `${days} hari lagi`
  }

  return remainingDays
}

export const Loaders = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 gap-2">
      <div className="spinner-grow text-danger" role="status">
        <span className="visually-hidden">Loading ...</span>
      </div>
      <div className="spinner-grow text-info" role="status">
        <span className="visually-hidden">Loading ...</span>
      </div>
      <div className="spinner-grow text-success" role="status">
        <span className="visually-hidden">Loading ...</span>
      </div>
    </div>
  )
}