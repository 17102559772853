import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FloatingLabel } from 'react-bootstrap'
import axios from 'axios'

const UpdateProfile = () => {

  const location = useLocation()
  const dataDetailEmployees = location.state.dataDetailEmployees
  const navigate = useNavigate()
  const dataEmployees = location.state.dataEmployees

  const { nama_lengkap, alamat, domisili, jenis_kelamin, tempat_lahir, tanggal_lahir, no_hp, ktp, agama, pendidikan, status_pernikahan, status_pajak, nik } = dataDetailEmployees

  let place_of_birth = new Date(tanggal_lahir)
  let day = place_of_birth.getDate().toString().padStart(2, '0');
  let month = place_of_birth.toLocaleString("default", { month: "2-digit" })
  let year = place_of_birth.getFullYear()
  let dateOfBirth = `${year}-${month}-${day}`

  const handleFormUpdateProfile = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formUpdateProfile = Object.fromEntries(formData)

    const username = localStorage.getItem('username')

    const reqDataEmpolyees = {
      nik: nik,
      new_nama_lengkap: formUpdateProfile.fullNameNew,
      new_jenis_kelamin: formUpdateProfile.ganderNew,
      new_tempat_lahir: formUpdateProfile.placeOfBirthNew,
      new_tanggal_lahir: formUpdateProfile.dateOfBirthNew,
      new_alamat: formUpdateProfile.newAlamat,
      new_domisili: formUpdateProfile.newDomisili,
      new_no_hp: formUpdateProfile.newHp,
      new_ktp: formUpdateProfile.newKTP,
      new_agama: formUpdateProfile.religionNew,
      new_pendidikan: formUpdateProfile.educationNew,
      new_status_pernikahan: formUpdateProfile.statusPernikahanNew,
      new_status_pajak: formUpdateProfile.statusPajakNew,
      new_updated_by: username
    }

    axios.put(`${process.env.REACT_APP_URL}/employees`, reqDataEmpolyees)
      .then((res) => {
        if (res.data) {
          alert('Berhasil mengubah Profile Karyawan')
          navigate(`/detailEmployee/${nik}`, { state: { dataEmployees: dataEmployees } })
        }
      }).catch((err) => {
        alert('Gagal mengubah Profile Karyawan Periksa Lagi !!!!')
        console.log('error', err)
      })
  }

  return (
    <>
      <div className='container bg-body-secondary d-flex flex-column rounded shadow shadow-sm'>
        <form className='d-flex p-2 gap-5 w-100' onSubmit={handleFormUpdateProfile}>
          <div className='d-flex flex-column w-50'>
            <label className='fs-5 fw-bold'>Nama Lengkap</label>
            <FloatingLabel label='Masukan Nama Lengkap yg ingin diubah'>
              <input type='text' name='fullNameNew' defaultValue={nama_lengkap} className='form-control ' />
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Jenis Kelamin</label>
            <FloatingLabel label={jenis_kelamin}>
              <select className="form-select" name='ganderNew'>
                <option value={jenis_kelamin}>Pilih Jenis Kelamin yg ingin diubah</option>
                <option value="LAKI-LAKI">Laki-Laki</option>
                <option value="PEREMPUAN">Perempuan</option>
              </select>
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Tempat Lahir</label>
            <FloatingLabel label='Masukan Tempat Lahir yg ingin diubah'>
              <input type='text' name='placeOfBirthNew' defaultValue={tempat_lahir} className='form-control' />
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Tanggal Lahir</label>
            <FloatingLabel label="Masukan Tanggal Lahir Baru">
              <input type='date' name='dateOfBirthNew'
                defaultValue={dateOfBirth}
                className='form-control' />
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Agama</label>
            <FloatingLabel label={agama}>
              <select className="form-select" name='religionNew'>
                <option value={agama}>Pilih Agama yg ingin diubah</option>
                <option value="Islam">Islam</option>
                <option value="Kristen Katolik">Kristen Katolik</option>
                <option value="Kristen Protestan">Kristen Protestan</option>
              </select>
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Pendidikan</label>
            <FloatingLabel label={pendidikan}>
              <select className="form-select" name='educationNew' >
                <option value={pendidikan}>Pilih Pendidikan yang ingin diubah</option>
                <option value="SD">SD</option>
                <option value="SMP">SMP</option>
                <option value="SMA">SMA</option>
                <option value="D1">D1</option>
                <option value="D2">D2</option>
                <option value="D3">D3</option>
                <option value="D4">D4</option>
                <option value="S1">S1</option>
                <option value="S2">S2</option>
              </select>
            </FloatingLabel>
          </div>
          <div className='d-flex flex-column w-50'>
            <label className='fs-5 fw-bold'>Status Pernikahan</label>
            <FloatingLabel label={status_pernikahan}>
              <select className="form-select" name='statusPernikahanNew'>
                <option value={status_pernikahan}>Pilih Status Pernikahan yg ingin diubah</option>
                <option value="Menikah">Menikah</option>
                <option value="Belum Menikah">Belum Menikah</option>
              </select>
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Status Pajak</label>
            <FloatingLabel label={status_pajak}>
              <select className="form-select" name='statusPajakNew'>
                <option value={status_pajak}>Pilih Status Pajak yg ingin diubah</option>
                <option value="B0">B0</option>
                <option value="K0">K0</option>
                <option value="K1">K1</option>
                <option value="K2">K2</option>
                <option value="K3">K3</option>
                <option value="K4">K4</option>
                <option value="K5">K5</option>
              </select>
            </FloatingLabel>
            <label className='fs-5 fw-bold'>No Handphone</label>
            <FloatingLabel label='Masukan No Hp yg ingin Di Ubah'>
              <input type='number' name='newHp' defaultValue={no_hp} className='form-control' />
            </FloatingLabel>
            <label className='fs-5 fw-bold'>No KTP</label>
            <FloatingLabel label='Masukan No KTP yg ingin Di Ubah'>
              <input type='number' name='newKTP' defaultValue={ktp} className='form-control' />
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Alamat</label>
            <FloatingLabel label="Ubah Alamat">
              <textarea className="form-control" name='newAlamat' defaultValue={alamat} style={{ height: "90px" }}></textarea>
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Domisili</label>
            <FloatingLabel label='Masukan Domisili yg ingin Di Ubah'>
              <input type='text' name='newDomisili' defaultValue={domisili} className='form-control' />
            </FloatingLabel>
          </div>
          <div className='d-flex flex-column'>
            <button type='submit' className='btn btn-lg fs-2 h-25'>
              <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default UpdateProfile