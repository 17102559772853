import React from 'react'

const NotFound = () => {
  return (
    <>
      <div className='fs-1 text-danger text-center vh-100 d-flex justify-content-center align-items-center'>404 | Not Found</div>
    </>
  )
}

export default NotFound;
