import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Tab, Tabs } from 'react-bootstrap'

const InputVacation = () => {

  const location = useLocation()
  const dataDetailEmployees = location.state.dataDetailEmployees
  const [saveFile, setSaveFile] = useState('')
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  const { nik, nama_lengkap } = dataDetailEmployees
  const nameUnitForAlert = (category) => {
    const categoryMap = {
      cut: 'Cuti Tahunan',
      cup: 'Cuti Periodik',
      cum: 'Cuti Melahirkan',
      cub: 'Cuti Bersama',
      off: 'OFF'
    }
    return categoryMap[category]
  }
  const dataLabel = [
    { label: 'No Surat', name: 'noLetter' },
    { label: 'NIK', value: 'nik', name: 'nik' },
    { label: 'Nama', value: 'nama_lengkap', name: 'fullname' },
    { label: 'Unit', value: 'unit', name: 'unit' },
    { label: 'Jabatan', value: 'jabatan', name: 'position' },
    { label: 'Tgl Cuti', name: 'dateVacation' },
    { label: 'Tgl Kembali Kerja', name: 'dateWork' },
    { label: 'Total Cuti', name: 'total' },
    { label: 'Keterangan', name: 'info' }
  ]

  const handleFileLetter = (e) => {
    const uploded = e.target.files[0]
    setSaveFile(uploded)
  }

  const handleFormVacation = (e) => {
    e.preventDefault()

    const formVacation = e.target
    
    const noLetter = formVacation.noLetter.value
    const category = formVacation.category.value
    const nik = formVacation.nik.value
    const fullname = formVacation.fullname.value
    const unit = formVacation.unit.value
    const dateVacation = formVacation.dateVacation.value
    const dateWork = formVacation.dateWork.value
    const total = formVacation.total.value
    const info = formVacation.info.value

    const formData = new FormData()
    formData.append('office', saveFile)
    formData.append('filename', saveFile.name)
    formData.append('no_letter', noLetter)
    formData.append('nik', nik)
    formData.append('category', category)
    formData.append('fullname', fullname)
    formData.append('unit', unit)
    formData.append('date_vacation', dateVacation)
    formData.append('date_work', dateWork)
    formData.append('total', total)
    formData.append('info', info)
    formData.append('updated_by', admin)

    axios.post(`${process.env.REACT_APP_URL}/vacation`, formData)
      .then((res) => {
        if (res.data.addDataVacation) {
          alert(`${nameUnitForAlert(category)} ${nama_lengkap} berhasil ditambah`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => console.log('err', err))
  }

  const handleFormVacationNotLetter = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formVacationNotLetter = Object.fromEntries(formData)

    const reqDataNotLetter = {
      no_letter: formVacationNotLetter.noLetter,
      category: formVacationNotLetter.category,
      nik: formVacationNotLetter.nik,
      fullname: formVacationNotLetter.fullname,
      unit: formVacationNotLetter.unit,
      date_vacation: formVacationNotLetter.dateVacation,
      date_work: formVacationNotLetter.dateWork,
      total: formVacationNotLetter.total,
      info: formVacationNotLetter.info,
      updated_by: admin
    }

    axios.post(`${process.env.REACT_APP_URL}/vacation/not_file`, reqDataNotLetter)
      .then((res) => {
        if (res.data.addDataVacation) {
          alert(`${nameUnitForAlert(formVacationNotLetter.category)} ${nama_lengkap} berhasil ditambah`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => console.log('eror', err))
  }

  return (
    <>
      <div className='container  animate__animated animate__fadeInDown'>
        <Tabs defaultActiveKey="useLetter" className='mb-1'>
          <Tab eventKey='useLetter' title='PAKAI SURAT'>
            <form onSubmit={handleFormVacation} className='shadow p-2 bg-light rounded'>
              <select className='form-select ms-5 mt-2 w-25' name='category' required>
                <option value='cut'>Cuti Tahunan</option>
                <option value='cup'>Cuti Periodik</option>
                <option value='cub'>Cuti Bersama</option>
                <option value='cum'>Cuti Melahirkan</option>
                <option value='off'>OFF</option>
              </select>
              {dataLabel.map((item, i) => {
                const inputType = item.label === 'Tgl Cuti' || item.label === 'Tgl Kembali Kerja' ? 'date' : item.label === 'Total Cuti' || item.label === 'NIK' ? 'number' : 'text';
                const inputWidth = inputType === 'date' || inputType === 'number' ? 'w-25' : 'w-50';
                const defaultValue = dataDetailEmployees[item.value]
                return (
                  <div key={i} className='d-flex align-items-center p-2 w-75'>
                    <label className='w-25 text-end fs-5 fw-bold '>{`${item.label} :`}</label>
                    <input
                      type={inputType}
                      className={`input-underlined ms-3 ${inputWidth}`}
                      placeholder={`Masukan ${item.label}`}
                      defaultValue={defaultValue}
                      name={`${item.name}`}
                      required
                    />
                  </div>
                )
              })}
              <div className='d-flex align-items-center p-2 w-75 rounded'>
                <label className='w-25 text-end fs-5 fw-bold'>Surat Cuti :</label>
                <input
                  type='file'
                  className='form-control ms-3 w-50 bg-secondary'
                  onChange={handleFileLetter}
                  required
                />
              </div>
              <button type='submit' className='btn btn-dark w-50 ms-5 mt-3'>Submit</button>
            </form>
          </Tab>
          <Tab eventKey='notLetter' title='TANPA SURAT'>
            <form onSubmit={handleFormVacationNotLetter} className='shadow p-2 bg-light rounded'>
              <select className='form-select ms-5 mt-2 w-25' name='category' required>
                <option value='cut'>Cuti Tahunan</option>
                <option value='cup'>Cuti Periodik</option>
                <option value='cub'>Cuti Bersama</option>
                <option value='cum'>Cuti Melahirkan</option>
                <option value='off'>OFF</option>
              </select>
              {dataLabel.map((item, i) => {
                const inputType = item.label === 'Tgl Cuti' || item.label === 'Tgl Kembali Kerja' ? 'date' : item.label === 'Total Cuti' ? 'number' : 'text';
                const inputWidth = inputType === 'date' || inputType === 'number' ? 'w-25' : 'w-50';
                const defaultValue = dataDetailEmployees[item.value]
                return (
                  <div key={i} className='d-flex align-items-center p-2 w-75'>
                    <label className='w-25 text-end fs-5 fw-bold '>{`${item.label} :`}</label>
                    <input
                      type={inputType}
                      className={`input-underlined ms-3 ${inputWidth}`}
                      placeholder={`Masukan ${item.label}`}
                      defaultValue={defaultValue}
                      name={`${item.name}`}
                      required
                    />
                  </div>
                )
              })}
              <button type='submit' className='btn btn-dark w-50 ms-5 mt-3'>Submit</button>
            </form>
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

export default InputVacation;
