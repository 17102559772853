import React, { useEffect, useState } from 'react'
import { Form, Button, FloatingLabel } from 'react-bootstrap'
import axios from 'axios'
import logoCadas from '../../Assets/Logo/CADAS.png'
import 'animate.css'
import { useNavigate } from 'react-router-dom'

const Login = () => {

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [version, setVersion] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/version`)
      .then((res) => setVersion(res.data.version))
      .catch((err) => console.log('err', err.message))
  })

  const userLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const formLogin = Object.fromEntries(formData);

    axios.post(`${process.env.REACT_APP_URL}/users/login`, {
      username: formLogin.userName,
      password: formLogin.password
    }, { withCredentials: true })
      .then(() => {
        window.location.replace('/dashboard')
      }).catch((err) => {
        err.response.data.msg === 'username not found'
          ? alert('username tidak ada !')
          : err.response.data.msg === 'password wrong'
            ? alert('password salah !')
            : console.error('err', err.response.data)
        setLoading(false)
      })
  }

  return (
    <div className='vh-100 d-flex justify-content-center align-items-center flex-column animate__animated animate__fadeInDown'>
      <Form className='mt-2 w-50 border border-1 p-4 rounded shadow d-flex align-items-center flex-column' onSubmit={userLogin}>
        <img src={logoCadas} alt='logoCADAS' className='w-50 h-75 mb-2' />
        <h4 className='w-75 title-h1'>Silahkan Login...</h4>
        <FloatingLabel label='Username' className='mb-2 w-75'>
          <Form.Control type='text' name='userName' placeholder='Username' className='focus-ring focus-ring-light fw-bold' required />
        </FloatingLabel>
        <FloatingLabel label='Password' className=' w-75' >
          <Form.Control type={showPassword ? 'text' : 'password'} name='password' placeholder='Password' className=' focus-ring focus-ring-light fw-bold' required />
        </FloatingLabel>
        <div className='form-check mb-3 mt-1 d-flex gap-2 w-75 ms-5' >
          <input type='checkbox' className='form-check-input' checked={showPassword}
            onChange={() => setShowPassword(!showPassword)} />
          <label className='form-check-label text-secondary'>Lihat Password</label>
        </div>
        <Button className='mt-1 w-50' variant='dark' type='submit' disabled={loading}>
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' aria-hidden='true'></span>
            </>
          ) : (
            'LOGIN'
          )}
        </Button>
        <div className='mt-2 text-secondary fw-bold'
          onClick={() => navigate('/verify-email')}
          style={{ cursor: "pointer" }}>Lupa Password</div>
        <p className="mt-4 text-secondary">&copy; 2024 | BKG</p>
        <p className="text-secondary" style={{ marginTop: "-15px", fontSize: "13px" }}>v - {version}</p>
      </Form>
    </div>
  )
}

export default Login;
