import axios from 'axios'
import React, { useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const UpdateWarningLetter = () => {

  const location = useLocation()
  const dataWarningLetterByNik = location.state.dataWarningLetterByNik
  const dataDetailEmployees = location.state.dataDetailEmployees
  const { nik, nama_lengkap } = dataDetailEmployees
  const formRefs = useRef([])
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  const labelWarningLetter = [
    { label: 'No Surat', type: 'text', w: 'w-50', name: 'noLetter', value: 'no_letter' },
    { label: 'Tahun', type: 'number', name: 'year', value: 'year' },
    { label: 'Jenis SP', type: 'select', option: ['SP 1', 'SP 2', 'SP 3'], name: 'type', value: 'type' },
    { label: 'Tanggal Surat', type: 'date', name: 'dateLetter', value: 'date_letter' },
    { label: 'Tanggal Berlaku', type: 'date', name: 'dateStart', value: 'date_start' },
    { label: 'Tanggal Akhir SP', type: 'date', name: 'dateEnd', value: 'date_end' },
    { label: 'Keterangan SP', type: 'text-area', name: 'info', value: 'info' }
  ]

  const handleFormUpdateWarningLetter = (e, noLetter) => {
    e.preventDefault()

    const formRef = formRefs.current[noLetter]
    if (formRef) {
      const formData = new FormData(formRef)
      const formWarningLetter = Object.fromEntries(formData.entries())

      const reqDataUpdateWarningLetter = {
        no_letter: noLetter,
        new_year: formWarningLetter.year,
        new_type: formWarningLetter.type,
        new_info: formWarningLetter.info,
        new_date_letter: formWarningLetter.dateLetter,
        new_date_start: formWarningLetter.dateStart,
        new_date_end: formWarningLetter.dateEnd,
        new_updated_by: admin
      }

      axios.put(`${process.env.REACT_APP_URL}/warningletters`, reqDataUpdateWarningLetter)
        .then((res) => {
          if (res.data.updateDataWarningLetter) {
            alert(`Berhasil Update Surat Peringatan ${nama_lengkap}`)
            navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
          }
        })
        .catch((err) => console.log('err', err))
    }

  }

  return (
    <div className='container bg-body-secondary rounded shadow-sm p-3  d-flex flex-column gap-2'>
      {dataWarningLetterByNik.map((item, i) => (
        <form
          key={i}
          ref={wl => formRefs.current[item.no_letter] = wl}
          onSubmit={(e) => handleFormUpdateWarningLetter(e, item.no_letter)}
          className='p-2 bg-light rounded border'>
          {labelWarningLetter.map((items, j) => (
            <div key={j} className='d-flex gap-2 p-1'>
              <label className='w-25 fw-bold fs-5 text-end'>{items.label} : </label>
              {items.type === 'select' ? (
                <select className='input-underlined w-25' defaultValue={item[items.value]} name={items.name}>
                  {items.option.map((option, k) => (
                    <option key={k} value={option}>{option}</option>
                  ))}
                </select>
              ) : items.type === 'text-area' ? (
                <textarea
                  style={{ height: '80px' }}
                  className='form-control w-75'
                  name={items.name}
                  defaultValue={item[items.value]}></textarea>
              ) : (
                <input
                  type={items.type}
                  className={`input-underlined w-25 ${items.w}`}
                  name={items.name}
                  defaultValue={item[items.value]} />
              )}
            </div>
          ))}
          <button type='submit' className='btn btn-primary btn-md w-75 ms-5 text-dark mt-3 mb-2'>Update</button>
        </form>
      ))}
    </div>
  )
}

export default UpdateWarningLetter