import React, { useRef, useState, useEffect } from "react"
import { Navbar } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ReactTypingEffect from 'react-typing-effect'
import Search from '../Search'
import AddUser from '../../../Users/Profile/AddUser'
import Profile from '../../../Users/Profile'
import { Tooltip } from 'react-tooltip'
import ToggleSwitch from './../ToggleSwitch';

const NavigationBar = ({ title, logoUnit, namaLengkap, nik, searchValue, setSearchValue, suggestionSearch, logoBK, logoAE, logoSN, setDatePeriodePerformance, getPreviousMonthYear, isOn, setIsOn, username, isAdmin, master }) => {

  const [showProfile, setShowProfile] = useState(false)
  const [showAddUser, setShowAddUser] = useState(false)

  const navigate = useNavigate()
  const formProfileRef = useRef(null)
  const formAddUserRef = useRef(null)
  const currentPath = window.location.pathname

  const formProfile = () => {
    setShowProfile(!showProfile)
  }

  const formAddUser = () => {
    setShowAddUser(!showAddUser)
  }

  const handleClickOutSide = (e) => {
    if (formProfileRef.current && !formProfileRef.current.contains(e.target)) {
      setShowProfile(false)
    } else if (formAddUserRef.current && !formAddUserRef.current.contains(e.target)) {
      setShowAddUser(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide)
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide)
    }
  }, []);

  return (
    <Navbar className="sticky-top shadow-sm" style={{ backdropFilter: 'blur(4px)' }}>
      <Navbar.Brand href="/dashboard" className="fw-semibold ms-5 fs-4 d-flex flex-row gap-2"
        style={{ textShadow: '1px 1px 2px rgba(0 0 0 /0.5)' }}>Database
        <div className="animate__animated animate__rotateInUpRight ">
          <span className="text-danger"> BK</span>
          <span className="text-primary">G</span>
        </div>
      </Navbar.Brand>
      {(currentPath === '/dashboard') &&
        <div className="position-absolute top-0 end-0 me-5 mt-2 d-flex">
          <i className="bi bi-person-circle btn btn-sm fs-4"
            onClick={formProfile} data-tooltip-id="profile-tooltip"
            data-tooltip-content="Profile"><span className="title-h1 ms-2 text-decoration-underline text-body-secondary">{username}</span>
          </i>
          <Tooltip id="profile-tooltip" place="left" />
          {showProfile && (
            <Profile formProfileRef={formProfileRef} username={username} />
          )}
        </div>
      }
      {(currentPath === '/addEmployee') &&
        <ReactTypingEffect
          text={[title]}
          className='fs-3 fs-italic position-absolute top-0 end-0 me-5 mt-2'
          speed={100}
          eraseDelay={300}
          eraseSpeed={100}
          typingDelay={100}
        />
      }
      {(currentPath === '/dataEmployee') &&
        <div className="position-absolute top-0 end-0 me-5 mt-2 d-flex">
          <Search searchValue={searchValue} setSearchValue={setSearchValue} suggestionSearch={suggestionSearch} />
          {isAdmin && (
            <i className="bi bi-plus-lg btn btn-sm fs-4" onClick={() => navigate('/addEmployee')}
              data-tooltip-id="plus-employee-tooltip" data-tooltip-content="Tambah Karyawan"
            ></i>
          )}
          <Tooltip id="plus-employee-tooltip" className="fw-bold card" />
          <ToggleSwitch isOn={isOn} setIsOn={setIsOn} tooltipId="toggle-tooltip"
            tooltipContent="Karyawan non aktif" />
          <Tooltip id="toggle-tooltip" place="bottom" />
          {master && (
            <i className="bi bi-person-plus-fill btn btn-sm fs-4"
              onClick={formAddUser}
              data-tooltip-id="plus-user-tooltip" data-tooltip-content="Tambah User"
            ></i>
          )}
          <Tooltip id="plus-user-tooltip" />
          {showAddUser && (
            <AddUser formAddUserRef={formAddUserRef} />
          )}
          <i className="bi bi-person-circle btn btn-sm fs-4"
            onClick={formProfile} data-tooltip-id="profile-tooltip"
            data-tooltip-content="Profile">
          </i>
          <Tooltip id="profile-tooltip" />
          {showProfile && (
            <Profile formProfileRef={formProfileRef} username={username} />
          )}
        </div>
      }
      {(currentPath === `/detailEmployee/${nik}`) && (
        <div>
          <h2 className="poetsen-one-regular"
            style={{ position: "fixed", right: "500px", top: '11px' }}
          >{namaLengkap}</h2>
          <img src={logoUnit} alt="logo Unit" className="position-absolute top-50 end-0 translate-middle"
            style={{ width: "150px", height: "45px" }} />
        </div>
      )}
      {(currentPath === '/detailEmployee/updateDataEmployee') && (
        <div>
          <h2 className="poetsen-one-regular"
            style={{ position: "fixed", right: "500px", top: '11px' }}
          >{namaLengkap}</h2>
          <ReactTypingEffect
            text={[title]}
            className='fs-3 fs-italic position-absolute top-0 end-0 me-5 mt-2'
            speed={100}
            eraseDelay={300}
            eraseSpeed={100}
            typingDelay={100}
          />
        </div>
      )}
      {(currentPath === '/dataEmployee/baraKumala' || currentPath === '/dataLicense/BK' || currentPath === '/dataVacation/BK' || currentPath === '/rekapPerformanceBK') && (
        <>
          <h2 className="poetsen-one-regular"
            style={{ position: "fixed", right: "500px", top: '11px' }}
          >{title}</h2>
          <img src={logoBK} alt="logo BK" className="position-absolute top-50 end-0 translate-middle"
            style={{ width: "150px", height: "45px" }} />
        </>
      )}
      {(currentPath === '/dataEmployee/aditama' || currentPath === '/dataLicense/AE' || currentPath === '/dataVacation/AE' || currentPath === '/rekapPerformanceAE') && (
        <>
          <h2 className="poetsen-one-regular"
            style={{ position: "fixed", right: "500px", top: '11px' }}
          >{title}</h2>
          <img src={logoAE} alt="logo AE" className="position-absolute top-50 end-0 translate-middle"
            style={{ width: "150px", height: "45px" }} />
        </>
      )}
      {(currentPath === '/dataEmployee/sinet' || currentPath === '/dataLicense/SN' || currentPath === '/dataVacation/SN' || currentPath === '/rekapPerformanceSN') && (
        <>
          <h2 className="poetsen-one-regular"
            style={{ position: "fixed", right: "500px", top: '11px' }}
          >{title}</h2>
          <img src={logoSN} alt="logo AE" className="position-absolute top-50 end-0 translate-middle"
            style={{ width: "150px", height: "45px" }} />
        </>
      )}
      {(currentPath === '/inputPerformance') && (
        <div>
          <div className='title-h1 position-absolute top-50 start-50 translate-middle fs-2'>Update Performance Karyawan</div>
          <div className='d-flex gap-2 position-absolute top-50 end-0 translate-middle'>
            <label className='fs-4 fw-bold'>Periode</label>
            <input type='month' className='form-control' onChange={(e) => setDatePeriodePerformance(e.target.value)} defaultValue={getPreviousMonthYear()} />
          </div>
        </div>
      )}
      {(currentPath === '/input') && (
        <div>
          <h2 className="poetsen-one-regular"
            style={{ position: "fixed", right: "500px", top: '11px' }}
          >{namaLengkap}</h2>
          <ReactTypingEffect
            text={[title]}
            className='fs-3 fs-italic position-absolute top-0 end-0 me-5 mt-2'
            speed={100}
            eraseDelay={300}
            eraseSpeed={100}
            typingDelay={100}
          />
        </div>
      )}
    </Navbar >
  )
}

export default NavigationBar