import React from 'react'
import { useLocation } from 'react-router-dom'
import NavigationBar from '../../Components/Utility/Navbar'
import logoSN from '../../Assets/Logo/SINET.png'

const RekapVacationSN = ({ title }) => {
  const location = useLocation()
  const dataVacation = location.state.dataVacation
  const dataEmployees = location.state.dataEmployees

  const dataEmployee = dataEmployees.filter(item => item.unit.toLowerCase() === 'sinet' && item.status.toLowerCase() === 'aktif' && (item.ikatan_kerja.toLowerCase() === 'kontrak' || item.ikatan_kerja.toLowerCase() === 'tetap'))

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des']
  const dataLabel = [
    'No.', 'NIK', 'Nama', ...months, 'Total', 'Sisa'
  ]

  const groupVacationByEmployeeAndMonth = (nik, month) => {
    const vacationData = dataVacation.filter(vacation =>
      vacation.nik === nik && new Date(vacation.date_vacation).getMonth() === month && (vacation.category === 'cut' || vacation.category === 'cub')
    )
    return vacationData.reduce((total, vacation) => total + vacation.total, 0)
  }

  return (
    <>
      <NavigationBar logoSN={logoSN} title={title} />
      <div className='m-2'>
        <table className='table table-bordered border-dark mt-4 animate__animated animate__fadeInDown' >
          <thead className='table-success table-bordered border-dark sticky-header'>
            <tr>
              {dataLabel.map((label, i) => (
                <th key={i} className='text-center fw-bold fs-5'>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataEmployee.filter(employee => {
              const monthlyVacations = months.map((_, monthIndex) =>
                groupVacationByEmployeeAndMonth(employee.nik, monthIndex)
              )
              const totalVacation = monthlyVacations.reduce((total, monthTotal) => total + monthTotal, 0)
              return totalVacation > 0;
            }).map((employee, j) => {
              const monthlyVacations = months.map((_, monthIndex) =>
                groupVacationByEmployeeAndMonth(employee.nik, monthIndex)
              )
              const totalVacation = monthlyVacations.reduce((total, monthTotal) => total + monthTotal, 0)

              const remainingVacation = 12 - totalVacation

              return (
                <tr key={j} className='text-center'>
                  <td>{j + 1}</td>
                  <td>{employee.nik}</td>
                  <td className='text-start'>{employee.nama_lengkap}</td>
                  {monthlyVacations.map((total, k) => (
                    <td key={k}>{(total === 0 ? '-' : total)}</td>
                  ))}
                  <td>{(totalVacation === 0 ? '-' : totalVacation)}</td>
                  <td className='text-danger'>{remainingVacation} Hari</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default RekapVacationSN
