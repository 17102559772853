import React, { useEffect, useState } from 'react'
import { dateFormated, isValueEmpty } from './../Components/Utility/index';
import { Tooltip } from 'react-tooltip';
import { deleteVacation } from './Delete';

const Vacation = ({ dataDetailEmployees, dataVacationByNik, isAdmin }) => {

  const { awal_pkwt_1 } = dataDetailEmployees
  const [totalVacation, setTotalVacation] = useState(0)

  const workDayDuration = (new Date() - new Date(awal_pkwt_1)) / (1000 * 60 * 60 * 24)
  const dayVacation = workDayDuration >= 365 ? 12 : 'Belum dapat hak cuti';
  const remainningDayVacation = dayVacation !== 'Belum dapat hak cuti' ? dayVacation - totalVacation : 0

  useEffect(() => {
    const total = dataVacationByNik.reduce((acc, item) => {
      if (item.category === 'cut' || item.category === 'cub') {
        return acc + item.total;
      }
      return acc
    }, 0);
    setTotalVacation(total)
  }, [dataVacationByNik])

  const downloadFile = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', '')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      {dayVacation !== 'BELUM DAPAT JATAH CUTI' && (
        <div className='container mt-2 card mb-2 animate__animated animate__fadeInDown'>
          <div className='title-h1 text-center fs-1'>Masa Cuti</div>
          {dataVacationByNik.length > 0 && (
            <table className='table table-bordered border-dark table-hover mt-1 mb-4'>
              <thead className='table-success table-bordered border-dark'>
                <tr className='text-center fs-5'>
                  <th>No.</th>
                  <th>No Cuti</th>
                  <th>Tanggal</th>
                  <th>Kembali Kerja</th>
                  <th>Lama Cuti</th>
                  <th colSpan={2}>Keterangan</th>
                </tr>
              </thead>
              <tbody>
                {dataVacationByNik.map((items, i) => {
                  const { id_letter } = items
                  return (
                    <tr key={i} className='text-center'>
                      <td className='fw-bold'>{i + 1}</td>
                      <td>{items.no_letter}
                        {!isValueEmpty(items.filename) && (
                          <>
                            <i className="bi bi-download text-primary ms-3" style={{ cursor: 'pointer' }}
                              onClick={() => downloadFile(`${process.env.REACT_APP_URL}/vacation/${items.filename}`)}
                              data-tooltip-id='download-file-tooltip' data-tooltip-content='download file'
                            ></i>
                          </>
                        )}
                        <Tooltip id='download-file-tooltip' place='bottom' />
                      </td>
                      <td>{dateFormated(items.date_vacation)}</td>
                      <td>{dateFormated(items.date_work)}</td>
                      <td>{items.total} Hari</td>
                      <td className='text-start'>{items.info}</td>
                      <td>
                        {isAdmin && (
                          <i className="bi bi-trash text-danger" style={{ cursor: 'pointer' }}
                            onClick={() => deleteVacation({ id_letter })}
                            data-tooltip-id='delete-vacation-tooltip' data-tooltip-content='hapus cuti'
                          ></i>
                        )}
                        <Tooltip id='delete-vacation-tooltip' place='bottom' />
                      </td>
                    </tr>
                  )
                })}
                <tr className='text-center fw-bold fs-4'>
                  <td colSpan={4}>Total Cuti Tahunan</td>
                  <td colSpan={4}>{totalVacation === 0 ? "" : `${totalVacation} Hari`}
                    <span className='text-primary ms-1'>({remainningDayVacation === 0 ? "Cuti Tahunan habis" : (`sisa ${remainningDayVacation} Hari`)})</span>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div >
      )}
    </>
  )
}

export default Vacation