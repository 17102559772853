import React, { useState } from 'react'
import { FloatingLabel } from 'react-bootstrap';
import logoBKG from '../../Assets/Logo/BKG.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Email = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const handleFormEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const formEmail = Object.fromEntries(formData);

    axios.post(`${process.env.REACT_APP_URL}/users/req-verify`, {
      email: formEmail.email
    })
      .then((res) => {
        if (res.data.codeVerify) {
          alert('kode verifikasi sudah dikirim cek email anda sekarang')
          navigate('/verify-code', { state: { email: formEmail.email } })
        }
      })
      .catch((err) => {
        console.log('err', err.message)
        if (err.response.status === 404) {
          alert('email tidak ada, coba lagi')
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className='vh-100 d-flex justify-content-center align-items-center flex-column animate__animated animate__fadeInDown'>
      <img src={logoBKG} alt='logoBKG' className='p-2 w-25' />
      <form onSubmit={handleFormEmail}
        className='d-flex flex-column gap-2 align-items-center card p-5 bg-body-secondary mt-2 shadow shadow-md'>
        <div className='fs-1 text-decoration-underline'>Kirim Kode Verifikasi ke Email</div>
        <FloatingLabel label='Masukan Email Anda' className='w-100 mt-3'>
          <input type='text' name='email' placeholder='Masukan Email Anda' className='form-control' required />
        </FloatingLabel>
        <button
          type='submit'
          className='btn btn-dark mt-2 w-75'
          disabled={loading}>
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' aria-hidden='true'></span>
            </>
          ) : (
            'Kirim Kode Verifikasi'
          )}
        </button>
        <button type='submit' onClick={() => navigate('/login')}
          className='btn btn-primary mt-1 w-50'>Kembali ke Login</button>
      </form>
    </div>
  )
}

export default Email;
