import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const InputDrivingLicense = ({ dataDetailEmployees }) => {

  const { nik, nama_lengkap, unit } = dataDetailEmployees
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  const labelLicense = [
    { label: 'No SIM', type: 'text', name: 'no_license' },
    {
      label: 'Jenis SIM', type: 'select',
      option: ['SIM-B2 UMUM', 'SIM-B2', 'SIM-B1 UMUM', 'SIM-B1', 'SIM-B', 'SIM-A'],
      name: 'type'
    },
    { label: 'Berlaku s/d', type: 'date', name: 'date_expired' },
    { label: 'Wilayah', type: 'text', name: 'region' },
    { label: 'Foto', type: 'file', name: 'filename' }
  ]

  const [imageLicense, setImageLicense] = useState(`${process.env.REACT_APP_URL}/license/card_add.png`)
  const [fileLicense, setFileLicense] = useState(null)

  const handleInputImageLicense = (e) => {
    const uploded = e.target.files[0]
    setImageLicense(URL.createObjectURL(uploded))
    setFileLicense(uploded)
  }

  const handleFormLicense = (e) => {
    e.preventDefault()
    const formLicense = e.target

    const noLicense = formLicense.no_license.value
    const type = formLicense.type.value
    const dateExpired = formLicense.date_expired.value
    const region = formLicense.region.value

    const formData = new FormData()
    formData.append('image', fileLicense)
    formData.append('filename', fileLicense.name)
    formData.append('no_license', noLicense)
    formData.append('nik', nik)
    formData.append('fullname', nama_lengkap)
    formData.append('unit', unit)
    formData.append('type', type)
    formData.append('date_expired', dateExpired)
    formData.append('region', region)
    formData.append('updated_by', admin)

    axios.post(`${process.env.REACT_APP_URL}/license`, formData)
      .then((res) => {
        if (res.data.dataDrivingLicense) {
          alert(`Berhasil menambahkan data sim ${nama_lengkap}`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => console.log('err', err))
  }

  return (
    <div className='container animate__animated animate__fadeInDown bg-body-secondary rounded p-2'>
      <form onSubmit={handleFormLicense} className='d-flex gap-2 flex-column'>
        {labelLicense.map((item, i) => (
          <div key={i} className='d-flex'>
            <label className='w-25 text-end fs-5 fw-bold'>{item.label} : </label>
            {item.type === 'select' ? (
              <select className='form-control w-25 ms-2' name={item.name} required>
                {item.option.map((option, j) => (
                  <option key={j} value={option}>{option}</option>
                ))}
              </select>
            ) : item.type === 'file' ? (
              <div className='d-flex flex-column gap-2 w-50'>
                <input type={item.type} className='form-control ms-2 w-50'
                  placeholder={`Masukan ${item.label}`}
                  name={item.name}
                  accept="image/*"
                  onChange={handleInputImageLicense}
                  required
                />
                <img src={imageLicense} alt='foto sim' className='w-50 ms-2 card p-2'
                  style={{ width: "200px", height: "175px" }} />
              </div>
            ) : (
              <input type={item.type} className='form-control ms-2 w-25'
                placeholder={`Masukan ${item.label}`}
                name={item.name} required
              />
            )}
          </div>
        ))}
        <button type='submit' className='btn btn-dark w-75 ms-5 mt-2 mb-2'>Submit</button>
      </form>
    </div>
  )
}

export default InputDrivingLicense