import axios from "axios"

export const deleteEmployee = (nik) => {

  if (window.confirm("Apakah anda yakin akan menghapus data !")) {
    axios.delete(`${process.env.REACT_APP_URL}/employees`, { data: nik })
      .then(() => {
        alert('Berhasil menghapus Data')
        window.location.reload()
      })
      .catch((err) => {
        alert('gagal menghapus data karyawan')
        console.log('eror', err)
      })
  }
}