import React, { useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const UpdatePrestasi = ({ dataDetailEmployees }) => {

  const location = useLocation()
  const dataPrestasiByNik = location.state.dataPrestasiByNik
  const formRefs = useRef([])
  const navigate = useNavigate()
  const admin = localStorage.getItem('username')
  const { nik, nama_lengkap } = dataDetailEmployees

  const labelPrestasi = [
    { label: 'ID Prestasi', w: 'w-25', name: 'id_prestasi' },
    { label: 'Tahun', w: 'w-25', type: 'number', name: 'year' },
    { label: 'Prestasi', w: 'w-75', name: 'prestasi' },
    { label: 'Reward', w: 'w-50', name: 'reward' },
  ]

  const handleUpdatePrestasi = (e, id_prestasi) => {
    e.preventDefault()

    const formRef = formRefs.current[id_prestasi]
    if (formRef) {
      const formData = new FormData(formRef)
      const formUpdatePrestasi = Object.fromEntries(formData.entries())

      const reqUpdatePrestasi = {
        id_prestasi: id_prestasi,
        new_year: formUpdatePrestasi.year,
        new_prestasi: formUpdatePrestasi.prestasi,
        new_reward: formUpdatePrestasi.reward,
        new_updated_by: admin
      }

      axios.put(`${process.env.REACT_APP_URL}/prestasi`, reqUpdatePrestasi)
        .then((res) => {
          if (res.data.updateDataPrestasi) {
            alert(`Berhasil Update Prestasi ${nama_lengkap}`)
            navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
          }
        })
        .catch((err) => console.error('err', err))
    }
  }

  return (
    <div className='container bg-body-secondary d-flex flex-column gap-2 p-2 rounded shadow-sm'>
      {dataPrestasiByNik.map((items, i) => (
        <form
          key={i}
          className='p-3 card'
          ref={ep => formRefs.current[items.id_prestasi] = ep}
          onSubmit={(e) => handleUpdatePrestasi(e, items.id_prestasi)}
        >
          {labelPrestasi.map((item, j) => (
            <div key={j} className='p-2'>
              {item.label !== 'ID Prestasi' && (
                <>
                  <label className='fw-bold fs-4 text-end' style={{ width: '150px' }}>{item.label} : </label>
                  <input type={item.type} className={`input-underlined ms-2 ${item.w}`} defaultValue={items[item.name]} name={item.name} />
                </>
              )}
            </div>
          ))}
          <button type='submit' className='btn btn-info ms-5 w-75 text-dark mt-4'>Update</button>
        </form>
      ))}
    </div>
  )
}

export default UpdatePrestasi