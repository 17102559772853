import axios from "axios"

export const deleteDrivingLicense = (id_license) => {

  if (window.confirm("Apakah anda yakin akan menghapus data Sim Polisi!"))
    axios.delete(`${process.env.REACT_APP_URL}/license`, { data: { id_license } })
      .then(() => {
        alert("Berhasil menghapus data sim polisi")
        window.location.reload()
      })
      .catch((err) => {
        alert('gagal menghapus data SIM')
        console.log('err', err)
      })
}
