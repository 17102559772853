import axios from 'axios'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const UpdateNotes = () => {

  const location = useLocation()
  const dataNotesByNik = location.state.dataNotesByNik
  const dataDetailEmployees = location.state.dataDetailEmployees
  const navigate = useNavigate()
  const admin = localStorage.getItem('username')
  const { nik, nama_lengkap } = dataDetailEmployees

  const handleFormNotes = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formNotes = Object.fromEntries(formData)

    axios.put(`${process.env.REACT_APP_URL}/notes`, {
      id_notes: formNotes.id_notes,
      new_type: formNotes.type,
      new_notes: formNotes.notes,
      new_updated_by: admin
    }).then((res) => {
      if (res.data.updateDataNotes) {
        alert(`Berhasil Update Catatan ${formNotes.type} ${nama_lengkap}`)
        navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
      }
    })
      .catch((err) => {
        alert(`Gagal Update Catatan ${formNotes.type} ${nama_lengkap}`)
        console.log('err', err)
      })
  }

  return (
    <div className='container bg-body-secondary p-2 rounded'>
      {dataNotesByNik.map((item, i) => (
        <form key={i}
          onSubmit={(e) => handleFormNotes(e, item.id_notes)}>
          <div className='card p-2 m-2'>
            <input type='text' className='input-underlined' defaultValue={item.id_notes} style={{ display: 'none' }} name='id_notes' />
            <div className='d-flex gap-2 mt-2 w-100'>
              <label className='w-25 text-end fs-5 fw-bold'>Jenis : </label>
              <select className='form-control w-25' name='type' defaultValue={item.type}>
                <option value='Pekerjaan'>Pekerjaan</option>
                <option value='Keahlian'>Keahlian</option>
                <option value='Sosial'>Sosial</option>
                <option value='Absensi'>Absensi</option>
              </select>
            </div>
            <div className='d-flex gap-2 mt-2 w-100'>
              <label className='w-25 text-end fs-5 fw-bold'>Catatan : </label>
              <textarea className='form-control w-75' style={{ height: '150px' }}
                name='notes' defaultValue={item.notes}></textarea>
            </div>
            <button type='submit' className='btn btn-info w-75 ms-5 mt-3 mb-2'>Update</button>
          </div>
        </form>
      ))}
    </div>
  )
}

export default UpdateNotes