import React from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const InputPrestasi = ({ dataDetailEmployees }) => {

  const { nik, nama_lengkap, unit } = dataDetailEmployees
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  const labelPrestasi = [
    { label: 'Tahun', w: 'w-25', type: 'number', name: 'year' },
    { label: 'Prestasi', w: 'w-75', name: 'prestasi' },
    { label: 'Reward', w: 'w-50', name: 'reward' },
  ]

  const handleFormInputPrestasi = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formPrestasi = Object.fromEntries(formData)
    console.log(formPrestasi)

    const reqFormPrestasi = {
      nik: nik,
      fullname: nama_lengkap,
      unit: unit,
      year: formPrestasi.year,
      prestasi: formPrestasi.prestasi,
      reward: formPrestasi.reward,
      updated_by: admin
    }

    console.log('req', reqFormPrestasi)

    axios.post(`${process.env.REACT_APP_URL}/prestasi`, reqFormPrestasi)
      .then((res) => {
        if (res.data.dataPrestasi) {
          alert(`Berhasil menambah Prestasi ${nama_lengkap}`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => console.log('err', err))
  }

  return (
    <div className='container bg-body-secondary rounded shadow-sm animate__animated animate__fadeInDown'>
      <form onSubmit={handleFormInputPrestasi}>
        {labelPrestasi.map((item, i) => (
          <div key={i} className='d-flex align-items-center p-2'>
            <label className='fw-bold fs-4 text-end' style={{ width: '100px' }}>{item.label} : </label>
            <input type={item.type} className={`input-underlined ms-2 ${item.w}`} name={item.name} placeholder={`Masukan ${item.label}`} />
          </div>
        ))}
        <button type='submit' className='btn btn-dark w-75 ms-5 mt-3 mb-3'>Submit</button>
      </form>
    </div>
  )
}

export default InputPrestasi