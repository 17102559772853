import React, { useState } from 'react'
import axios from 'axios'
import { FloatingLabel } from 'react-bootstrap'
import NavigationBar from '../../Components/Utility/Navbar'

const AddEmployee = ({ title }) => {

  const [image, setImage] = useState(`${process.env.REACT_APP_URL}/employees/Add_user.png`)
  const [saveImage, setSaveImage] = useState(null)

  const handelInputImage = (e) => {
    const uploded = e.target.files[0]
    setImage(URL.createObjectURL(uploded))
    setSaveImage(uploded)
  }

  const handleSaveForm = (e) => {
    e.preventDefault();

    const form = e.target;

    const NIK = form.nik.value;
    const namaLengkap = form.namaLengkap.value;
    const jenisKelamin = form.jenisKelamin.value;
    const pendidikan = form.pendidikan.value;
    const status = form.status.value;
    const unit = form.unit.value;
    const HP = form.hp.value;
    const KTP = form.ktp.value;
    const alamat = form.alamat.value;
    const jabatan = form.jabatan.value;
    const divisi = form.divisi.value;
    const level = form.level.value;
    const lokasiKerja = form.lokasiKerja.value;
    const ikatanKerja = form.ikatanKerja.value;
    const domisili = form.domisili.value;
    const tempatLahir = form.tempatLahir.value;
    const tanggalLahir = form.tanggalLahir.value;
    const agama = form.agama.value;
    const statusPernikahaan = form.statusPernikahaan.value;
    const statusPajak = form.statusPajak.value;
    const bpjsKes = form.bpjsKes.value;
    const bpjsTK = form.bpjsTK.value;

    if (NIK === '') {
      return alert('NIK MASIH KOSONG')
    } else if (saveImage === null) {
      return alert('foto belum ada')
    }

    const username = localStorage.getItem('username')

    const formData = new FormData()
    formData.append('image', saveImage)
    formData.append('foto_karyawan', saveImage.name)
    formData.append('nik', NIK)
    formData.append('nama_lengkap', namaLengkap)
    formData.append('jenis_kelamin', jenisKelamin)
    formData.append('pendidikan', pendidikan)
    formData.append('status', status)
    formData.append('unit', unit)
    formData.append('ktp', KTP)
    formData.append('no_hp', HP)
    formData.append('alamat', alamat)
    formData.append('jabatan', jabatan)
    formData.append('divisi', divisi)
    formData.append('level', level)
    formData.append('lokasi_kerja', lokasiKerja)
    formData.append('ikatan_kerja', ikatanKerja)
    formData.append('domisili', domisili)
    formData.append('tempat_lahir', tempatLahir)
    formData.append('tanggal_lahir', tanggalLahir)
    formData.append('agama', agama)
    formData.append('status_pernikahan', statusPernikahaan)
    formData.append('status_pajak', statusPajak)
    formData.append('no_bpjs_kesehatan', bpjsKes)
    formData.append('no_bpjs_ketenagakerjaan', bpjsTK)
    formData.append('created_by', username)
    formData.append('updated_by', username)

    axios.post(`${process.env.REACT_APP_URL}/employees`, formData)
      .then((res) => {
        if (res.data.addDataEmployee) {
          alert('Input Data Karyawan Sukses')
          window.location.replace('/dataEmployee')
        } else {
          alert('Gagal Input Data Periksa Kembali Datanya ...!')
        }
      }).catch((err) => {
        console.log(err)
        alert('Gagal menambah data karyawan')
      })
  }

  return (
    <>
      <NavigationBar title={title} />
      <form onSubmit={handleSaveForm} className='d-flex flex-column container mt-4 bg-body-secondary rounded was-validated animate__animated animate__fadeInDown'>
        <fieldset>
          <div className="d-flex flex-row p-4">
            <div className="d-flex flex-column w-50 gap-1 border-end border-1 border-black pe-3">
              <legend className="text-decoration-underline mt-1">DATA PRIBADI KARYAWAN</legend>
              <div className='was-validated'>
                <FloatingLabel label="Nama Lengkap">
                  <input
                    type="text"
                    placeholder="Nama lengkap"
                    className="form-control w-75"
                    name="namaLengkap"
                    defaultValue=""
                    required
                  />
                </FloatingLabel>
              </div>
              <div className="d-flex flex-row gap-2">
                <div className="d-flex flex-column gap-1">
                  <FloatingLabel label="Jenis Kelamin">
                    <select className="form-select" name="jenisKelamin" defaultValue="" required>
                      <option value="">Pilih Jenis Kelamin</option>
                      <option value="LAKI-LAKI">Laki-Laki</option>
                      <option value="PEREMPUAN">Perempuan</option>
                    </select>
                  </FloatingLabel>
                  <FloatingLabel label="Pendidikan">
                    <select className="form-select" name="pendidikan" defaultValue="" required>
                      <option value="">Pilih Pendidikan</option>
                      <option value="SD">SD</option>
                      <option value="SMP">SMP</option>
                      <option value="SMA">SMA</option>
                      <option value="D1">D1</option>
                      <option value="D2">D2</option>
                      <option value="D3">D3</option>
                      <option value="D4">D4</option>
                      <option value="S1">S1</option>
                      <option value="S2">S2</option>
                    </select>
                  </FloatingLabel>
                  <FloatingLabel label="NO HP">
                    <input type="text" placeholder="NO HP" className="form-control" name="hp" defaultValue="" />
                  </FloatingLabel>
                  <FloatingLabel label="NO KTP">
                    <input type="number" className="form-control" placeholder="NO KTP" name="ktp" defaultValue="" required />
                  </FloatingLabel>
                </div>
                <div className="card w-50 d-flex align-items-center gap-1">
                  <img
                    src={image} alt="foto"
                    style={{ width: "200px", height: "175px" }}
                  />
                  <label>Upload foto disini 👇</label>
                  <input type="file"
                    className="form-control mt-1 form-control-sm"
                    accept="image/*"
                    onChange={handelInputImage}
                    required />
                </div>
              </div>
              <FloatingLabel label="Alamat">
                <textarea className="form-control" placeholder="Alamat" name="alamat" style={{ height: "90px" }} defaultValue="" required></textarea>
              </FloatingLabel>
              <div className='d-flex align-items-center gap-2'>
                <FloatingLabel label="Domisili">
                  <input type="text" className="form-control" placeholder="Domisili" name="domisili" defaultValue="" required />
                </FloatingLabel>
                <FloatingLabel label="Tempat Lahir">
                  <input type="text" className="form-control" placeholder="Tempat Lahir" name="tempatLahir" defaultValue="" required />
                </FloatingLabel>
                <FloatingLabel label="Tanggal Lahir">
                  <input type="date" className="form-control" placeholder="Tanggal Lahir" name="tanggalLahir" defaultValue="" required />
                </FloatingLabel>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <FloatingLabel label="Agama">
                  <select className="form-select" name="agama" defaultValue="" required>
                    <option value="">Pilih Agama</option>
                    <option value="Islam">Islam</option>
                    <option value="Kristen Katolik">Kristen Katolik</option>
                    <option value="Kristen Protestan">Kristen Protestan</option>
                  </select>
                </FloatingLabel>
                <FloatingLabel label="Status Pernikahan">
                  <select className="form-select" name="statusPernikahaan" defaultValue="" required>
                    <option value="">Pilih Status Pernikahan</option>
                    <option value="MENIKAH">Menikah</option>
                    <option value="BELUM MENIKAH">Belum Menikah</option>
                  </select>
                </FloatingLabel>
                <FloatingLabel label="Status Pajak">
                  <select className="form-select" name="statusPajak" defaultValue="" required>
                    <option value="">Stts Pajak</option>
                    <option value="B0">B0</option>
                    <option value="K0">K0</option>
                    <option value="K1">K1</option>
                    <option value="K2">K2</option>
                    <option value="K3">K3</option>
                    <option value="K4">K4</option>
                    <option value="K5">K5</option>
                  </select>
                </FloatingLabel>
              </div>
            </div>
            <div className="d-flex flex-column w-50 gap-1 ps-3">
              <legend className="text-decoration-underline mt-1">DATA PERUSAHAAN</legend>
              <div className='d-flex justify-content-between gap-2'>
                <FloatingLabel label="NIK">
                  <input
                    type="number"
                    placeholder="NIK"
                    className="form-control w-75"
                    name="nik"
                    minLength={6}
                    defaultValue=""
                    required
                  />
                </FloatingLabel>
                <FloatingLabel label="Lokasi Kerja">
                  <select className="form-select" name="lokasiKerja" defaultValue="" required>
                    <option value="">Pilih Lokasi</option>
                    <option value="SITE">SITE</option>
                    <option value="SMD">SMD</option>
                  </select>
                </FloatingLabel>
                <FloatingLabel label="Status">
                  <select className="form-select" name="status" defaultValue="" required>
                    <option value="">Pilih Status</option>
                    <option value="AKTIF">AKTIF</option>
                    <option value="NON AKTIF">NON AKTIF</option>
                  </select>
                </FloatingLabel>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <FloatingLabel label="Unit">
                  <select className="form-select" name="unit" defaultValue="" required>
                    <option value="">Pilih Unit Karyawan Bekerja</option>
                    <option value="ADITAMA ENERGI">ADITAMA ENERGI</option>
                    <option value="BARA KUMALA">BARA KUMALA</option>
                    <option value="SINET">SINET</option>
                  </select>
                </FloatingLabel>
                <FloatingLabel label="Ikatan Kerja">
                  <select className="form-select" name="ikatanKerja" defaultValue="" required>
                    <option value="">Ikatan Kerja Karyawan</option>
                    <option value="KONTRAK">KONTRAK</option>
                    <option value="TETAP">TETAP</option>
                    <option value="HARIAN">HARIAN</option>
                  </select>
                </FloatingLabel>
              </div>
              <FloatingLabel label="Jabatan">
                <input type="text" className="form-control" placeholder="Jabatan" name="jabatan" defaultValue="" required />
              </FloatingLabel>
              <div className='d-flex justify-content-center gap-2'>
                <FloatingLabel label="Divisi">
                  <select className="form-select" name="divisi" defaultValue="" required>
                    <option value="">Pilih Divisi Karyawan</option>
                    <option value="Produksi">Produksi</option>
                    <option value="Safety">Safety</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Perencanaan">Perencanaan</option>
                    <option value="Umum">Umum</option>
                    <option value="Explorasi">Explorasi</option>
                    <option value="Keuangan">Keuangan</option>
                    <option value="Logistik">Logistik</option>
                    <option value="Rental Heavy Equipment">Rental Heavy Equipment</option>
                    <option value="Coal Processing And Shipping">Coal Processing And Shipping</option>
                    <option value="HSSE, Tambang, Compliance">HSSE, Tambang, Compliance</option>
                    <option value="Peralatan">Peralatan</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Security">Security</option>
                  </select>
                </FloatingLabel>
                <FloatingLabel label="Level">
                  <select className="form-select" name="level" defaultValue="" required>
                    <option value="">Pilih Level Karyawan</option>
                    <option value="NON STAF & PELAKSANA">NON STAF & PELAKSANA</option>
                    <option value="SUPERVISI & FOREMAN">SUPERVISI & FOREMAN</option>
                    <option value="MANAGER & SUPER INTENDENT">MANAGER & SUPER INTENDENT</option>
                  </select>
                </FloatingLabel>
              </div>
              <FloatingLabel label="No BPJS KESEHATAN">
                <input
                  type="number"
                  placeholder="No BPJS KESEHATAN"
                  className="form-control w-50"
                  name="bpjsKes"
                  defaultValue=""
                />
              </FloatingLabel>
              <FloatingLabel label="No BPJS KETENAGAKERJAAN">
                <input
                  type="text"
                  placeholder="No BPJS KETENAGAKERJAAN"
                  className="form-control w-50"
                  name="bpjsTK"
                  defaultValue=""
                />
              </FloatingLabel>
            </div>
          </div>
          <button className='btn btn-dark w-100 mb-2' type="submit">SAVE</button>
        </fieldset>
      </form>
    </>
  )
}

export default AddEmployee
