import axios from 'axios'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const InputNotes = () => {

  const location = useLocation()
  const dataDetailEmployees = location.state.dataDetailEmployees
  const { nik, nama_lengkap, unit } = dataDetailEmployees
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  const handleFormNotes = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formNotes = Object.fromEntries(formData)

    axios.post(`${process.env.REACT_APP_URL}/notes`, {
      nik: nik,
      fullname: nama_lengkap,
      unit: unit,
      type: formNotes.type,
      notes: formNotes.notes,
      updated_by: admin
    })
      .then((res) => {
        if (res.data.dataNotes) {
          alert(`Berhasil Menambahkan catatan ${formNotes.type} ${nama_lengkap}`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => {
        alert(`gagal menambahkan catatan ${nama_lengkap}`)
        console.log('err', err)
      })
  }

  return (
    <div className='container animate__animated animate__fadeInDown'>
      <form onSubmit={handleFormNotes} className='bg-body-secondary p-2 rounded shadow-sm'>
        <div className='d-flex gap-2 mt-2 w-100'>
          <label className='w-25 text-end fs-5 fw-bold'>Jenis : </label>
          <select className='form-control w-25' name='type'>
            <option value='Pekerjaan'>Pekerjaan</option>
            <option value='Keahlian'>Keahlian</option>
            <option value='Sosial'>Sosial</option>
            <option value='Absensi'>Absensi</option>
          </select>
        </div>
        <div className='d-flex gap-2 mt-2 w-100'>
          <label className='w-25 text-end fs-5 fw-bold'>Catatan : </label>
          <textarea className='form-control w-75' style={{ height: '150px' }} name='notes'></textarea>
        </div>
        <button type='submit' className='btn btn-dark w-75 mt-3 mb-2 ms-5 p-2'>Submit</button>
      </form>
    </div >
  )
}

export default InputNotes