import React from 'react'
import { months } from '../../../Components/Utility'

const HOK = ({ filterDataPerformanceByNik }) => {

  const dataHOKByMonth = months.map((date) => {
    const data = filterDataPerformanceByNik('hok', date)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const total = dataHOKByMonth.reduce((acc, value) => {
    return value !== '-' ? acc + parseFloat(value) : acc
  }, 0)

  return (
    <tbody>
      <tr className='text-center'>
        <td className='fw-bold'>HOK</td>
        {dataHOKByMonth.map((value, i) => (
          <td key={i}>{value !== '-' ? `${value} ` : value}</td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{total !== 0 ? `${total} hari` : '-'}</td>
      </tr>
    </tbody>
  )
}

export default HOK