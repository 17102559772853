import React from 'react'
import { useNavigate } from 'react-router-dom'
import logoAE from '../../Assets/Logo/Aditama.png'
import logoBK from '../../Assets/Logo/BK.png'
import logoSN from '../../Assets/Logo/SINET.png'
import { countAge, isValueEmpty } from '../Utility'

const EmployeesDashboard = ({ dataEmployees }) => {

  const navigate = useNavigate('')

  const filterEmployeesByCriteria = (unit, status, jabatan, jenis_kelamin, lokasi_kerja, pendidikan) => {
    return dataEmployees.filter(employee => {
      const matchStatus = status ? employee.status.toLowerCase() === status.toLowerCase() : true;
      const matchUnit = unit ? employee.unit.toLowerCase() === unit.toLowerCase() : true;
      const matchPosition = jabatan ? employee.jabatan.toLowerCase() === jabatan.toLowerCase() : true;
      const matchGender = jenis_kelamin ? employee.jenis_kelamin.toLowerCase() === jenis_kelamin.toLowerCase() : true;
      const matchWorkLocation = lokasi_kerja ? employee.lokasi_kerja.toLowerCase() === lokasi_kerja.toLowerCase() : true;
      const matchEducation = pendidikan ? employee.pendidikan.toLowerCase() === pendidikan.toLowerCase() : true;

      return matchStatus && matchUnit && matchPosition && matchGender && matchWorkLocation && matchEducation;
    });
  }

  const countEmployeesByFilter = (unit, status, jabatan, jenis_kelamin, lokasi_kerja, pendidikan) => {
    return filterEmployeesByCriteria(unit, status, jabatan, jenis_kelamin, lokasi_kerja, pendidikan).length;
  }

  const totalEmployees = countEmployeesByFilter(null, 'aktif')

  const totalEmployeesAE = countEmployeesByFilter('aditama energi', 'aktif')
  const totalOperatorExcaAE = countEmployeesByFilter('aditama energi', 'aktif', 'operator excavator')
  const totalOperatorAdtAE = countEmployeesByFilter('aditama energi', 'aktif', 'operator adt')
  const totalOperatorDozerAE = countEmployeesByFilter('aditama energi', 'aktif', 'operator dozer')
  const totalOperatorLoaderAE = countEmployeesByFilter('aditama energi', 'aktif', 'operator loader')
  const totalOperatorGreaderAE = countEmployeesByFilter('aditama energi', 'aktif', 'operator greader')
  const totalOperatorDtAE = countEmployeesByFilter('aditama energi', 'aktif', 'driver dump truck')
  const totalOperatorWtAE = countEmployeesByFilter('aditama energi', 'aktif', 'driver water truck')

  const totalEmployeesBK = countEmployeesByFilter('bara kumala', 'aktif')
  const totalOperatorExcaBK = countEmployeesByFilter('bara kumala', 'aktif', 'operator excavator')
  const totalOperatorAdtBK = countEmployeesByFilter('bara kumala', 'aktif', 'operator adt')
  const totalOperatorDozerBK = countEmployeesByFilter('bara kumala', 'aktif', 'operator dozer')
  const totalOperatorLoaderBK = countEmployeesByFilter('bara kumala', 'aktif', 'operator loader')
  const totalOperatorGreaderBK = countEmployeesByFilter('bara kumala', 'aktif', 'operator greader')
  const totalOperatorDtBK = countEmployeesByFilter('bara kumala', 'aktif', 'driver dump truck')
  const totalOperatorWtBK = countEmployeesByFilter('bara kumala', 'aktif', 'driver water truck')

  const totalEmployeesSN = countEmployeesByFilter('sinet', 'aktif')
  const totalOperatorExcaSN = countEmployeesByFilter('sinet', 'aktif', 'operator excavator')
  const totalOperatorAdtSN = countEmployeesByFilter('sinet', 'aktif', 'operator adt')
  const totalOperatorDozerSN = countEmployeesByFilter('sinet', 'aktif', 'operator dozer')
  const totalOperatorLoaderSN = countEmployeesByFilter('sinet', 'aktif', 'operator loader')
  const totalOperatorGreaderSN = countEmployeesByFilter('sinet', 'aktif', 'operator greader')
  const totalOperatorDtSN = countEmployeesByFilter('sinet', 'aktif', 'driver dump truck')
  const totalOperatorWtSN = countEmployeesByFilter('sinet', 'aktif', 'driver water truck')

  const totalGenderMenAE = countEmployeesByFilter('aditama energi', 'aktif', null, 'laki-laki')
  const totalGenderWomenAE = countEmployeesByFilter('aditama energi', 'aktif', null, 'perempuan')
  const totalGenderMenBK = countEmployeesByFilter('bara kumala', 'aktif', null, 'laki-laki')
  const totalGenderWomenBK = countEmployeesByFilter('bara kumala', 'aktif', null, 'perempuan')
  const totalGenderMenSN = countEmployeesByFilter('sinet', 'aktif', null, 'laki-laki')
  const totalGenderWomenSN = countEmployeesByFilter('sinet', 'aktif', null, 'perempuan')

  const totalWorkLocationSiteAE = countEmployeesByFilter('aditama energi', 'aktif', null, null, 'site')
  const totalWorkLocationSmdAE = countEmployeesByFilter('aditama energi', 'aktif', null, null, 'smd')
  const totalWorkLocationSiteBK = countEmployeesByFilter('bara kumala', 'aktif', null, null, 'site')
  const totalWorkLocationSmdBK = countEmployeesByFilter('bara kumala', 'aktif', null, null, 'smd')
  const totalWorkLocationSiteSN = countEmployeesByFilter('sinet', 'aktif', null, null, 'site')
  const totalWorkLocationSmdSN = countEmployeesByFilter('sinet', 'aktif', null, null, 'smd')

  const totalEducationSdAE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 'sd')
  const totalEducationSdBK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 'sd')
  const totalEducationSdSN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 'sd')
  const totalEducationSmpAE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 'smp')
  const totalEducationSmpBK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 'smp')
  const totalEducationSmpSN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 'smp')
  const totalEducationSmaAE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 'sma')
  const totalEducationSmaBK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 'sma')
  const totalEducationSmaSN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 'sma')
  const totalEducationD1AE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 'd1')
  const totalEducationD1BK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 'd1')
  const totalEducationD1SN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 'd1')
  const totalEducationD2AE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 'd2')
  const totalEducationD2BK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 'd2')
  const totalEducationD2SN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 'd2')
  const totalEducationD3AE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 'd3')
  const totalEducationD3BK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 'd3')
  const totalEducationD3SN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 'd3')
  const totalEducationD4AE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 'd4')
  const totalEducationD4BK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 'd4')
  const totalEducationD4SN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 'd4')
  const totalEducationS1AE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 's1')
  const totalEducationS1BK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 's1')
  const totalEducationS1SN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 's1')
  const totalEducationS2AE = countEmployeesByFilter('aditama energi', 'aktif', null, null, null, 's2')
  const totalEducationS2BK = countEmployeesByFilter('bara kumala', 'aktif', null, null, null, 's2')
  const totalEducationS2SN = countEmployeesByFilter('sinet', 'aktif', null, null, null, 's2')

  const countEmployeesByAgeRangeAE = (minAge, maxAge) => {
    return dataEmployees.filter((employee) => {
      const unitMatch = employee.unit.toLowerCase() === 'aditama energi';
      const statusMatch = employee.status.toLowerCase() === 'aktif';
      const age = countAge(employee.tanggal_lahir);
      const ageMatch = (minAge === null || age >= minAge) && (maxAge === null || age <= maxAge);

      return unitMatch && statusMatch && ageMatch;
    }).length;
  }

  const totalAgeUnder30AE = countEmployeesByAgeRangeAE(0, 29)
  const totalAge30and40AE = countEmployeesByAgeRangeAE(30, 40)
  const totalAge41and50AE = countEmployeesByAgeRangeAE(41, 50)
  const totalAgeAbove50AE = countEmployeesByAgeRangeAE(51, null)

  const countEmployeesByAgeRangeBK = (minAge, maxAge) => {
    return dataEmployees.filter((employee) => {
      const unitMatch = employee.unit.toLowerCase() === 'bara kumala';
      const statusMatch = employee.status.toLowerCase() === 'aktif';
      const age = countAge(employee.tanggal_lahir);
      const ageMatch = (minAge === null || age >= minAge) && (maxAge === null || age <= maxAge);

      return unitMatch && statusMatch && ageMatch;
    }).length;
  }

  const totalAgeUnder30BK = countEmployeesByAgeRangeBK(0, 29)
  const totalAge30and40BK = countEmployeesByAgeRangeBK(30, 40)
  const totalAge41and50BK = countEmployeesByAgeRangeBK(41, 50)
  const totalAgeAbove50BK = countEmployeesByAgeRangeBK(51, null)

  const countEmployeesByAgeRangeSN = (minAge, maxAge) => {
    return dataEmployees.filter((employee) => {
      const unitMatch = employee.unit.toLowerCase() === 'sinet';
      const statusMatch = employee.status.toLowerCase() === 'aktif';
      const age = countAge(employee.tanggal_lahir);
      const ageMatch = (minAge === null || age >= minAge) && (maxAge === null || age <= maxAge);

      return unitMatch && statusMatch && ageMatch;
    }).length;
  }

  const totalAgeUnder30SN = countEmployeesByAgeRangeSN(0, 29)
  const totalAge30and40SN = countEmployeesByAgeRangeSN(30, 40)
  const totalAge41and50SN = countEmployeesByAgeRangeSN(41, 50)
  const totalAgeAbove50SN = countEmployeesByAgeRangeSN(51, null)

  return (
    <>
      <div className='container d-flex flex-column gap-3 justify-content-center mt-2 '>
        <div className='text-start align-items-end'>
          <div className='title-h1 fs-3 me-5'>Total Karyawan : {totalEmployees}<i className="fs-4 fw-bold bi bi-chevron-right ms-auto btn btn-sm mb-1" onClick={() => navigate('/dataEmployee')}></i></div>
        </div >
        <div className='d-flex gap-3'>
          <div className='card d-flex flex-column flex-grow-1 align-items-center bg-light shadow shadow-sm animate__animated animate__fadeInDown mb-2'>
            <div className='border-bottom border-2 p-2  text-center w-100'>
              <img src={logoAE} alt='logo AE' className='logo-dashboard' />
            </div>
            <div className='title-h1 fs-3 w-75 d-flex'>Karyawan : {totalEmployeesAE}<i className="fs-4 fw-bold bi bi-chevron-right ms-auto btn btn-sm" onClick={() => navigate('/dataEmployee/aditama', { state: { dataEmployees: dataEmployees } })}></i></div>
            <ul className='w-75 fs-5'>
              {!isValueEmpty(totalOperatorExcaAE) && (
                <li>Operator Excavator : {totalOperatorExcaAE}</li>
              )}
              {!isValueEmpty(totalOperatorAdtAE) && (
                <li>Operator ADT : {totalOperatorAdtAE}</li>
              )}
              {!isValueEmpty(totalOperatorDozerAE) && (
                <li>Operator Dozer : {totalOperatorDozerAE}</li>
              )}
              {!isValueEmpty(totalOperatorLoaderAE) && (
                <li>Operator Loader : {totalOperatorLoaderAE}</li>
              )}
              {!isValueEmpty(totalOperatorGreaderAE) && (
                <li>Operator Greader : {totalOperatorGreaderAE}</li>
              )}
              {!isValueEmpty(totalOperatorDtAE) && (
                <li>Driver Dump Truck  : {totalOperatorDtAE}</li>
              )}
              {!isValueEmpty(totalOperatorWtAE) && (
                <li>Driver Water Truck  : {totalOperatorWtAE}</li>
              )}
              <div className='title-h1'>Gender</div>
              <li>Laki-Laki : {totalGenderMenAE} </li>
              <li>Perempuan : {totalGenderWomenAE} </li>
              <div className='title-h1'>Lokasi Kerja</div>
              <li>Site : {totalWorkLocationSiteAE}</li>
              <li>Samarinda : {totalWorkLocationSmdAE}</li>
              <div className='title-h1'>Pendidikan</div>
              {!isValueEmpty(totalEducationSdAE) && (
                <li>SD : {totalEducationSdAE}</li>
              )}
              {!isValueEmpty(totalEducationSmpAE) && (
                <li>SMP : {totalEducationSmpAE}</li>
              )}
              {!isValueEmpty(totalEducationSmaAE) && (
                <li>SMA : {totalEducationSmaAE}</li>
              )}
              {!isValueEmpty(totalEducationD1AE) && (
                <li>D1 : {totalEducationD1AE}</li>
              )}
              {!isValueEmpty(totalEducationD2AE) && (
                <li>D2 : {totalEducationD2AE}</li>
              )}
              {!isValueEmpty(totalEducationD3AE) && (
                <li>D3 : {totalEducationD3AE}</li>
              )}
              {!isValueEmpty(totalEducationD4AE) && (
                <li>D4 : {totalEducationD4AE}</li>
              )}
              {!isValueEmpty(totalEducationS1AE) && (
                <li>S1 : {totalEducationS1AE}</li>
              )}
              {!isValueEmpty(totalEducationS2AE) && (
                <li>S2 : {totalEducationS2AE}</li>
              )}
              <div className='title-h1'>Umur</div>
              <li> &lt;  30  : {totalAgeUnder30AE}</li>
              <li> 30 - 40  : {totalAge30and40AE}</li>
              <li> 41 - 50  : {totalAge41and50AE}</li>
              <li> 50 &gt; : {totalAgeAbove50AE}</li>
            </ul>
          </div>
          <div className='card d-flex flex-column flex-grow-1 bg-light align-items-center animate__animated animate__fadeInDown mb-2'>
            <div className='border-bottom border-2 p-2 text-center w-100' >
              <img src={logoBK} alt='logo BK' className='logo-dashboard' />
            </div>
            <div className='title-h1 fs-3 w-75 d-flex'>Karyawan : {totalEmployeesBK}<i className="fs-4 fw-bold bi bi-chevron-right ms-auto btn btn-sm" onClick={() => navigate('/dataEmployee/baraKumala', { state: { dataEmployees: dataEmployees } })}></i></div>
            <ul className='w-75 fs-5'>
              {!isValueEmpty(totalOperatorExcaBK) && (
                <li>Operator Excavator : {totalOperatorExcaBK}</li>
              )}
              {!isValueEmpty(totalOperatorAdtBK) && (
                <li>Operator ADT : {totalOperatorAdtBK}</li>
              )}
              {!isValueEmpty(totalOperatorDozerBK) && (
                <li>Operator Dozer : {totalOperatorDozerBK}</li>
              )}
              {!isValueEmpty(totalOperatorLoaderBK) && (
                <li>Operator Loader : {totalOperatorLoaderBK}</li>
              )}
              {!isValueEmpty(totalOperatorGreaderBK) && (
                <li>Operator Greader : {totalOperatorGreaderBK}</li>
              )}
              {!isValueEmpty(totalOperatorDtBK) && (
                <li>Driver Dump Truck  : {totalOperatorDtBK}</li>
              )}
              {!isValueEmpty(totalOperatorWtBK) && (
                <li>Driver Water Truck  : {totalOperatorWtBK}</li>
              )}
              <div className='title-h1'>Gender </div>
              <li>Laki-Laki : {totalGenderMenBK} </li>
              <li>Perempuan : {totalGenderWomenBK} </li>
              <div className='title-h1'>Lokasi Kerja</div>
              <li>Site : {totalWorkLocationSiteBK}</li>
              <li>Samarinda : {totalWorkLocationSmdBK}</li>
              <div className='title-h1'>Pendidikan</div>
              {!isValueEmpty(totalEducationSdBK) && (
                <li>SD : {totalEducationSdBK}</li>
              )}
              {!isValueEmpty(totalEducationSmpBK) && (
                <li>SMP : {totalEducationSmpBK}</li>
              )}
              {!isValueEmpty(totalEducationSmaBK) && (
                <li>SMA : {totalEducationSmaBK}</li>
              )}
              {!isValueEmpty(totalEducationD1BK) && (
                <li>D1 : {totalEducationD1BK}</li>
              )}
              {!isValueEmpty(totalEducationD2BK) && (
                <li>D2 : {totalEducationD2BK}</li>
              )}
              {!isValueEmpty(totalEducationD3BK) && (
                <li>D3 : {totalEducationD3BK}</li>
              )}
              {!isValueEmpty(totalEducationD4BK) && (
                <li>D4 : {totalEducationD4BK}</li>
              )}
              {!isValueEmpty(totalEducationS1BK) && (
                <li>S1 : {totalEducationS1BK}</li>
              )}
              {!isValueEmpty(totalEducationS2BK) && (
                <li>S2 : {totalEducationS2BK}</li>
              )}
              <div className='title-h1'>Umur</div>
              <li> &lt;  30  : {totalAgeUnder30BK}</li>
              <li> 30 - 40  : {totalAge30and40BK}</li>
              <li> 41 - 50  : {totalAge41and50BK}</li>
              <li> 50 &gt; : {totalAgeAbove50BK}</li>
            </ul>
          </div>
          <div className='card d-flex flex-column flex-grow-1 bg-light align-items-center animate__animated animate__fadeInDown mb-2'>
            <div className='border-bottom border-2 p-2 text-center w-100'>
              <img src={logoSN} alt='logo SN' className='logo-dashboard' />
            </div>
            <div className='title-h1 fs-3 w-75 d-flex'>Karyawan: {totalEmployeesSN}<i className="fs-4 fw-bold bi bi-chevron-right ms-auto btn btn-sm" onClick={() => navigate('/dataEmployee/sinet', { state: { dataEmployees: dataEmployees } })}></i></div>
            <ul className='w-75 fs-5'>
              {!isValueEmpty(totalOperatorExcaSN) && (
                <li>Operator Excavator : {totalOperatorExcaSN}</li>
              )}
              {!isValueEmpty(totalOperatorAdtSN) && (
                <li>Operator ADT : {totalOperatorAdtSN}</li>
              )}
              {!isValueEmpty(totalOperatorDozerSN) && (
                <li>Operator Dozer : {totalOperatorDozerSN}</li>
              )}
              {!isValueEmpty(totalOperatorLoaderSN) && (
                <li>Operator Loader : {totalOperatorLoaderSN}</li>
              )}
              {!isValueEmpty(totalOperatorGreaderSN) && (
                <li>Operator Greader : {totalOperatorGreaderSN}</li>
              )}
              {!isValueEmpty(totalOperatorDtSN) && (
                <li>Driver Dump Truck  : {totalOperatorDtSN}</li>
              )}
              {!isValueEmpty(totalOperatorWtSN) && (
                <li>Driver Water Truck  : {totalOperatorWtSN}</li>
              )}
              <div className='title-h1'>Gender </div>
              <li>Laki-Laki : {totalGenderMenSN} </li>
              <li>Perempuan : {totalGenderWomenSN} </li>
              <div className='title-h1'>Lokasi Kerja</div>
              <li>Site : {totalWorkLocationSiteSN}</li>
              <li>Samarinda : {totalWorkLocationSmdSN}</li>
              <div className='title-h1'>Pendidikan</div>
              {!isValueEmpty(totalEducationSdSN) && (
                <li>SD : {totalEducationSdSN}</li>
              )}
              {!isValueEmpty(totalEducationSmpSN) && (
                <li>SMP : {totalEducationSmpSN}</li>
              )}
              {!isValueEmpty(totalEducationSmaSN) && (
                <li>SMA : {totalEducationSmaSN}</li>
              )}
              {!isValueEmpty(totalEducationD1SN) && (
                <li>D1 : {totalEducationD1SN}</li>
              )}
              {!isValueEmpty(totalEducationD2SN) && (
                <li>D2 : {totalEducationD2SN}</li>
              )}
              {!isValueEmpty(totalEducationD3SN) && (
                <li>D3 : {totalEducationD3SN}</li>
              )}
              {!isValueEmpty(totalEducationD4SN) && (
                <li>D4 : {totalEducationD4SN}</li>
              )}
              {!isValueEmpty(totalEducationS1SN) && (
                <li>S1 : {totalEducationS1SN}</li>
              )}
              {!isValueEmpty(totalEducationS2SN) && (
                <li>S2 : {totalEducationS2SN}</li>
              )}
              <div className='title-h1'>Umur</div>
              <li> &lt;  30  : {totalAgeUnder30SN}</li>
              <li> 30 - 40  : {totalAge30and40SN}</li>
              <li> 41 - 50  : {totalAge41and50SN}</li>
              <li> 50 &gt; : {totalAgeAbove50SN}</li>
            </ul>
          </div>
        </div>
        <div className='detail-employee'
          onClick={() => navigate('/dataEmployee')}><i className="bi bi-arrow-right-square fs-1"></i></div>
      </div >
    </>
  )
}

export default EmployeesDashboard