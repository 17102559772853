import React from 'react'

const Evaluation = ({ dataEmployeeFiltered, dataPerformance }) => {
  const months = ['Q-1', 'Q-2', 'Q-3', 'Q-4']
  const dataLabel = ['No.', 'NIK', 'Nama', ...months, 'Average']

  const getMonthsForQuarter = (quarter) => {
    switch (quarter) {
      case 'Q-1':
        return [0, 1, 2]
      case 'Q-2':
        return [3, 4, 5]
      case 'Q-3':
        return [6, 7, 8]
      case 'Q-4':
        return [9, 10, 11]
      default:
        return []
    }
  }

  const groupPerformanceByEmployeeAndQuarter = (nik, quarter) => {
    const months = getMonthsForQuarter(quarter)
    const performanceData = dataPerformance.filter(
      (performance) =>
        performance.nik === nik &&
        months.includes(new Date(performance.periode).getMonth()) &&
        performance.category.toLowerCase() === 'nilai'
    )
    return performanceData.length > 0
      ? performanceData.reduce((total, performance) => total + Number(performance.value), 0)
      : '-'
  }

  return (
    <table className="table table-bordered border-dark">
      <thead className="table-danger table-bordered border-dark sticky-header">
        <tr>
          {dataLabel.map((label, i) => (
            <th key={i} className="text-center fw-bold">
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataEmployeeFiltered.map((items, j) => {
          const quarterlyPerformance = months.map((quarter) =>
            groupPerformanceByEmployeeAndQuarter(items.nik, quarter)
          )

          const validPerformances = quarterlyPerformance.filter((value) => value !== '-')

          const totalQuartersWithData = validPerformances.length
          const averagePerformance =
            totalQuartersWithData > 0
              ? (
                validPerformances.reduce((total, quarterTotal) => total + quarterTotal, 0) /
                totalQuartersWithData
              ).toFixed(2)
              : '-'

          return (
            <tr className="text-center" key={j}>
              <td>{j + 1}.</td>
              <td>{items.nik}</td>
              <td className="text-start">{items.nama_lengkap}</td>
              {quarterlyPerformance.map((total, k) => (
                <td key={k}>{total !== '-' ? total : '-'}</td>
              ))}
              <td className="fw-bold">{averagePerformance !== '0.00' ? `${averagePerformance}` : '-'}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Evaluation
