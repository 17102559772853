import React, { useEffect, useRef, useState } from 'react'
import NavigationBar from '../../Components/Utility/Navbar'
import logoAE from '../../Assets/Logo/Aditama.png'
import FilterEmployee from '../../Components/Utility/FilterEmployee'
import { useLocation, useNavigate } from 'react-router-dom'
import { countAge, generationCategory } from '../../Components/Utility'

const DataEmployeeAE = ({ title }) => {

  const location = useLocation()
  const dataEmployees = location.state.dataEmployees
  const formFilterRef = useRef(null)
  const navigate = useNavigate()

  const filterEmployeeByUnit = (status, unit) => {
    return dataEmployees.filter(employee => {
      const matchStatus = employee.status.toLowerCase() === status.toLowerCase();
      const matchUnit = employee.unit.toLowerCase() === unit.toLowerCase();

      return matchStatus && matchUnit;
    })
  }

  const dataEmployeeAE = filterEmployeeByUnit('aktif', 'aditama energi')

  const [selectedColumns, setSelectedColumns] = useState('')
  const [showFormFilter, setShowFormFilter] = useState(false)

  const formFilter = () => {
    setShowFormFilter(!showFormFilter)
  }

  const handleClickOutSide = (e) => {
    if (formFilterRef.current && !formFilterRef.current.contains(e.target)) {
      setShowFormFilter(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide)
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide)
    }
  })

  const handleRowClick = (nik) => {
    navigate(`/detailEmployee/${nik}`, { state: { from: '/dataEmployee/aditama', dataEmployees: dataEmployees } })
  }

  return (
    <>
      <NavigationBar logoAE={logoAE} title={title} />
      <i className="bi bi-arrow-left"
        onClick={() => navigate('/dashboard')}
      ></i>
      <div className='container mt-2 animate__animated animate__fadeInDown'>
        <table className='table table-bordered border-dark mt-4'>
          <thead className='table-info table-bordered border-dark sticky-header'>
            <tr className='text-center fs-4'>
              <th>No.</th>
              <th>NIK</th>
              <th>Nama Lengkap</th>
              <th>Jabatan</th>
              <th>Divisi</th>
              {selectedColumns.age && <th>Umur</th>}
              {selectedColumns.level && <th>Level</th>}
              {selectedColumns.workLocation && <th>Lokasi Kerja</th>}
              {selectedColumns.workBond && <th>Ikatan Kerja</th>}
              {selectedColumns.generationAge && <th>Generasi</th>}
              {selectedColumns.gender && <th>Jenis Kelamin</th>}
              {selectedColumns.religion && <th>Agama</th>}
              {selectedColumns.education && <th>Pendidikan</th>}
              {selectedColumns.weddingStatus && <th>Status Pernikahan</th>}
              {selectedColumns.taxStatus && <th>Status Pajak</th>}
              {selectedColumns.noHp && <th>No Handphone</th>}
              {selectedColumns.noBpjsKes && <th>No BPJS Kesehatan</th>}
              {selectedColumns.noBpjsKet && <th>No BPJS Ketenagakerjaan</th>}
            </tr>
          </thead>
          <tbody>
            {dataEmployeeAE.map((employee, i) => (
              <tr key={i}>
                <th className='text-center'>{i + 1}</th>
                <td className='text-center'>{employee.nik}</td>
                <td onClick={() => handleRowClick(employee.nik)} style={{ cursor: "pointer" }}>{employee.nama_lengkap}</td>
                <td>{employee.jabatan}</td>
                <td>{employee.divisi}</td>
                {selectedColumns.age && <td>{countAge(employee.tanggal_lahir)} tahun</td>}
                {selectedColumns.level && <td>{employee.level}</td>}
                {selectedColumns.workLocation && <td>{employee.lokasi_kerja}</td>}
                {selectedColumns.workBond && <td>{employee.ikatan_kerja}</td>}
                {selectedColumns.generationAge && <td>{generationCategory(employee.tanggal_lahir)}</td>}
                {selectedColumns.gender && <td>{employee.jenis_kelamin}</td>}
                {selectedColumns.religion && <td>{employee.agama}</td>}
                {selectedColumns.education && <td>{employee.pendidikan}</td>}
                {selectedColumns.weddingStatus && <td>{employee.status_pernikahan}</td>}
                {selectedColumns.taxStatus && <td>{employee.status_pajak}</td>}
                {selectedColumns.noHp && <td>{employee.no_hp}</td>}
                {selectedColumns.noBpjsKes && <td>{employee.no_bpjs_kesehatan}</td>}
                {selectedColumns.noBpjsKet && <td>{employee.no_bpjs_ketenagakerjaan}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className=''>
        <i className="bi bi-funnel fs-1 btn btn-sm" style={{ position: "fixed", top: "75px", right: "30px" }} onClick={formFilter}>
        </i>
        {showFormFilter && (
          <FilterEmployee selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} formFilterRef={formFilterRef} />
        )
        }
      </div>
    </>
  )
}

export default DataEmployeeAE