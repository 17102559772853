import React from 'react'
import { months } from '../../../Components/Utility'

const GoHome = ({ filterDataPerformanceByNik }) => {

  const dataGoHomeByMonth = months.map((date) => {
    const data = filterDataPerformanceByNik('plg', date)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const total = dataGoHomeByMonth.reduce((acc, value) => {
    return value !== '-' ? acc + parseFloat(value) : acc
  }, 0)

  return (
    <tbody>
      <tr className='text-center table-secondary border-dark'>
        <td className='fw-bold'>Pulang Cepat</td>
        {dataGoHomeByMonth.map((value, i) => (
          <td key={i}>{value !== '-' ? `${value}` : value}</td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{total !== 0 ? `${total} hari` : '-'}</td>
      </tr>
    </tbody>
  )
}

export default GoHome
