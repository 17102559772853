import axios from "axios"

export const deleteReminder = (id_reminder) => {
  console.log('id', id_reminder)

  if (window.confirm("Apakah anda yakin akan menghapus data reminder !")) {
    axios.delete(`${process.env.REACT_APP_URL}/reminder`, { data: id_reminder })
      .then(() => {
        alert('Reminder telah dihapus')
        window.location.reload()
      })
      .catch((err) => {
        alert('gagal menghapus reminder coba lagi !')
        console.log('eror', err.response.data)
      })
  }
}
