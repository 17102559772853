import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const InputFamily = () => {

  const location = useLocation()
  const dataDetailEmployees = location.state.dataDetailEmployees
  const { nik, unit, nama_lengkap } = dataDetailEmployees
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  const labelFamily = [
    { label: 'Nama Lengkap', type: 'text', name: 'fullname', widht: 'w-50' },
    { label: 'Hubungan', type: 'select', options: ['Anak', 'Istri', 'Suami'], name: 'relation' },
    { label: 'Jenis Kelamin', type: 'select', options: ['Perempuan', 'Laki-laki'], name: 'gender' },
    { label: 'Tempat Lahir', type: 'text', name: 'placeBirth' },
    { label: 'Tanggal Lahir', type: 'date', name: 'dateBirth' },
  ]

  const handleFormFamily = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formFamily = Object.fromEntries(formData)

    const reqFormFamily = {
      nik: nik,
      unit: unit,
      fullname: formFamily.fullname,
      gender: formFamily.gender,
      relation: formFamily.relation,
      place_birth: formFamily.placeBirth,
      date_birth: formFamily.dateBirth,
      updated_by: admin
    }

    if (formFamily.idNik === '') {
      return alert('NIK Keluarga masih kosong, Harap isi dulu !')
    }

    axios.post(`${process.env.REACT_APP_URL}/family`, reqFormFamily)
      .then((res) => {
        if (res.data.dataFamily) {
          alert(`berhasil memasukan data ${formFamily.relation} ${nama_lengkap}`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => {
        alert(`Gagal Menambahkan Data Keluarga ${nama_lengkap}`)
        console.log('eror', err)
      }
      )
  }

  return (
    <>
      <div className='container animate__animated animate__fadeInDown mt-1 bg-info rounded shadow'>
        <form onSubmit={handleFormFamily} className='p-2'>
          {labelFamily.map((family, i) => (
            <div key={i} className='p-2 d-flex gap-2 align-items-center'>
              <label className='w-25 text-end fs-5 fw-bold'>{family.label} : </label>
              {family.type === 'select' ? (
                <select className={`form-control w-25 ${family.widht}`} name={family.name} required>
                  {family.options.map((option, j) => (
                    <option key={j} value={option} >{option}</option>
                  ))}
                </select>
              ) : (
                <input type={family.type} className={`form-control w-25 ${family.widht}`} placeholder={`Masukan ${family.label}`} name={family.name} required />
              )}
            </div>
          ))}
          <button type='submit' className='btn btn-md btn-dark text-info w-75 mt-4 ms-5'>Submit</button>
        </form>
      </div>
    </>
  );
}

export default InputFamily
