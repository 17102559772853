import React from 'react'
import { dateFormated } from './../Components/Utility/index';

const Training = ({ dataTrainingByNik }) => {

  const labelTraining = [
    { label: 'No.' },
    { label: 'Pelatihan' },
    { label: 'Waktu' },
    { label: 'Tempat' },
    { label: 'Penyelenggara' },
    { label: 'Ket.' }
  ]

  return (
    <div className='container card animate__animated animate__fadeInDown mt-2'>
      <div className='title-h1 fs-1 text-center'>Pelatihan</div>
      <table className='table table-bordered table-hover border-dark'>
        <thead className='table table-bordered border-dark table-secondary'>
          <tr className='fw-bold text-center fs-5'>
            {labelTraining.map((item, i) => (
              <th key={i} colSpan={item.colSpan}>{item.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataTrainingByNik.map((items, j) => {
            const isSameDate = items.date_start === items.date_end
            const dateStart = new Date(items.date_start)
            const getDate = date => `${date.getDate()} ${date.toLocaleDateString('default', { month: "short" })}`;
            return (
              <tr key={j} className='text-center'>
                <td className='fw-bold'>{j + 1}.</td>
                <td className='text-start'>{items.training}</td>
                <td>{isSameDate ?
                  dateFormated(items.date_start) :
                  `${getDate(dateStart)} - ${dateFormated(items.date_end)}`}</td>
                <td>{items.place}</td>
                <td>{items.organizer}</td>
                <td>{items.info}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Training