import axios from 'axios'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const UpdateDrivingLicense = ({ dataDetailEmployees }) => {

  const location = useLocation()
  const dataDrivingLicenseByNik = location.state.dataDrivingLicenseByNik
  const filename = dataDrivingLicenseByNik.map(item => item.filename)
  const admin = localStorage.getItem('username')
  const { nama_lengkap, nik } = dataDetailEmployees
  const navigate = useNavigate()

  const labelLicense = [
    { label: 'Id SIM', type: 'text', name: 'id_license', hidden: true },
    { label: 'No SIM', type: 'text', name: 'no_license' },
    {
      label: 'Jenis SIM', type: 'select',
      option: ['SIM-B2 UMUM', 'SIM-B2', 'SIM-B1 UMUM', 'SIM-B1', 'SIM-B', 'SIM-A'],
      name: 'type'
    },
    { label: 'Berlaku s/d', type: 'date', name: 'date_expired' },
    { label: 'Wilayah', type: 'text', name: 'region' },
    { label: 'Foto', type: 'file' }
  ]

  const [oldImageLicense, setOldImageLicense] = useState(`${process.env.REACT_APP_URL}/license/${filename}`)
  const [saveNewImageLicense, setSaveNewImageLicense] = useState(null)

  const handleChangeImageLicense = (e) => {
    const uploded = e.target.files[0]
    setOldImageLicense(URL.createObjectURL(uploded))
    setSaveNewImageLicense(uploded)
  }

  const handleUpdateFormLicense = (e) => {
    e.preventDefault()
    if (!saveNewImageLicense) {
      return alert('Masukkan foto SIM terlebih dahulu');
    }
    const formLicense = e.target

    const idLicense = formLicense.id_license.value
    const noLicense = formLicense.no_license.value
    const type = formLicense.type.value
    const dateExpired = formLicense.date_expired.value
    const region = formLicense.region.value

    const formData = new FormData()
    formData.append('id_license', idLicense)
    formData.append('new_no_license', noLicense)
    formData.append('image', saveNewImageLicense)
    formData.append('new_type', type)
    formData.append('new_date_expired', dateExpired)
    formData.append('new_region', region)
    formData.append('new_updated_by', admin)

    axios.put(`${process.env.REACT_APP_URL}/license`, formData)
      .then((res) => {
        if (res.data.updateDataDrivingLicense) {
          alert(`Berhasil Update Data Sim ${nama_lengkap}`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => {
        alert('gagal update data sim polisi')
        console.log('err', err)
      })
  }

  return (
    <div className='container card bg-body-secondary p-2'>
      {dataDrivingLicenseByNik.map((items, j) => (
        <form key={j}
          onSubmit={handleUpdateFormLicense}
          className='d-flex gap-2 flex-column card p-4'>
          {labelLicense.map((item, i) => (
            <div key={i} className='d-flex'>
              <label
                className={`w-25 fw-bold fs-5 text-end ${item.hidden ? 'hidden' : ''}`}
                placeholder={`Masukan ${item.label}`}
              >{item.label} : </label>
              {item.type === 'select' ? (
                <select className='input-underlined w-25 ms-2' defaultValue={items[item.name]} name={item.name}>
                  {item.option.map((option, k) => (
                    <option key={k} value={option} >{option}</option>
                  ))}
                </select>
              ) : item.type === 'file' ? (
                <div className='d-flex flex-column gap-2 w-50'>
                  <input type={item.type} className='input-underlined ms-2 w-50'
                    placeholder={`Masukan ${item.label}`}
                    name={item.name}
                    accept="image/*"
                    onChange={handleChangeImageLicense}
                    required
                  />
                  <img src={oldImageLicense} alt='foto sim' className='w-50 ms-2 card p-2'
                    style={{ width: "200px", height: "175px" }} />
                </div>
              ) : (
                <input type={item.type}
                  className={`input-underlined ms-2 w-25 ${item.hidden ? 'hidden' : ''}`}
                  placeholder={`Masukan ${item.label}`}
                  name={item.name} defaultValue={items[item.name]}
                />
              )}
            </div>
          ))}
          <button type='submit' className='btn btn-info w-75 mb-2 ms-5 mt-4'>Update</button>
        </form>
      ))}
    </div>
  )
}

export default UpdateDrivingLicense