import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const InputTraining = ({ dataDetailEmployees }) => {

  const { nik, nama_lengkap, unit } = dataDetailEmployees
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  const labelTraining = [
    { label: 'Pelatihan', w: 'w-50', name: 'training' },
    { label: 'Tempat', w: 'w-50', name: 'place' },
    { label: 'Penyelenggara', w: 'w-50', name: 'organizer' },
    { label: 'Tanggal Mulai', type: 'date', name: 'date_start' },
    { label: 'Tanggal Selesai', type: 'date', name: 'date_end' },
    { label: 'Keterangan', w: 'w-50', name: 'info' }
  ]

  const handleFormTraining = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formTraining = Object.fromEntries(formData)

    const reqFormTraining = {
      fullname: nama_lengkap,
      nik: nik,
      unit: unit,
      organizer: formTraining.organizer,
      place: formTraining.place,
      date_start: formTraining.date_start,
      date_end: formTraining.date_end,
      training: formTraining.training,
      info: formTraining.info,
      updated_by: admin
    }

    axios.post(`${process.env.REACT_APP_URL}/training`, reqFormTraining)
      .then((res) => {
        if (res.data.dataTraining) {
          alert(`Berhasil menambahkan data Training ${nama_lengkap}`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => console.log('err', err))
  }

  return (
    <div className='container animate__animated animate__fadeInDown bg-body-secondary p-2 rounded shadow-sm'>
      <form onSubmit={handleFormTraining}
        className='p-2 d-flex flex-column gap-3'>
        {labelTraining.map((item, i) => (
          <div key={i} className='d-flex'>
            <label className='fs-5 text-end w-25 fw-bold'>{item.label} : </label>
            <input type={item.type} className={`ms-2 input-underlined w-25 ${item.w}`}
              placeholder={`Masukan ${item.label}`} name={item.name} required />
          </div>
        ))}
        <button type='submit' className='btn btn-dark w-75 ms-5 mt-4'>Submit</button>
      </form>
    </div>
  )
}

export default InputTraining