import React, { useState } from 'react';
import { FloatingLabel } from 'react-bootstrap';
import logoBKG from '../../Assets/Logo/BKG.png';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const ResetPassword = () => {

  const location = useLocation();
  const email = location.state.email;
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleFormPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const formPassword = Object.fromEntries(formData);

    if (formPassword.password !== formPassword.confPassword) {
      alert("Password dan confirmasi password tidak cocok")
    }

    axios.put(`${process.env.REACT_APP_URL}/users/reset-password`, {
      email: email,
      password: formPassword.password,
      confPassword: formPassword.confPassword
    })
      .then((res) => {
        if (res.data.resetPassword) {
          alert("Password sudah berhasil di reset silahkan Login")
          window.location = '/login'
        }
        setLoading(false)
      })
      .catch((err) => console.log('err', err.message))
  }

  return (
    <div className='vh-100 d-flex justify-content-center align-items-center flex-column animate__animated animate__fadeInDown'>
      <img src={logoBKG} alt='logoBKG' className='p-2 w-25' />
      <form onSubmit={handleFormPassword}
        className='d-flex flex-column gap-2 align-items-center card p-5  mt-2 shadow shadow-md'>
        <div className='fs-1 title-h1 text-decoration-underline'>Reset Password Anda</div>
        <FloatingLabel label='Masukan Password Baru anda' className='w-100 mt-3'>
          <input
            type={showPassword ? 'text' : 'password'}
            name='password'
            minLength={6}
            placeholder='Masukan Password Baru Anda'
            className='form-control'
            required
          />
        </FloatingLabel>
        <FloatingLabel label='Masukan Password Baru anda Sekali Lagi' className='w-100'>
          <input
            type={showPassword ? 'text' : 'password'}
            name='confPassword'
            minLength={6}
            placeholder='Masukan Password Baru Anda Sekali lagi'
            className='form-control'
            required
          />
        </FloatingLabel>
        <div className='form-check  mt-1 d-flex gap-2' >
          <input type='checkbox' className='form-check-input' checked={showPassword}
            onChange={() => setShowPassword(!showPassword)} />
          <label className='form-check-label text-secondary'>Lihat Password</label>
        </div>
        <button type='submit' className='btn btn-primary mt-2 w-75 text-dark'
          disabled={loading}>
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' aria-hidden='true'></span>
            </>
          ) : (
            'RESET PASSWORD'
          )}
        </button>
      </form>
    </div>
  )
}

export default ResetPassword
