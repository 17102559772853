import axios from 'axios';
import React from 'react'
import { FloatingLabel } from 'react-bootstrap'

const UpdateEmail = ({ username }) => {

  const handleUpdateEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formUpdate = Object.fromEntries(formData);

    const data = {
      username,
      email: formUpdate.oldEmail,
      new_email: formUpdate.newEmail
    }
    console.log(data)

    axios.put(`${process.env.REACT_APP_URL}/users/update-email`, data)
      .then((res) => {
        if (res.data.updateEmail) {
          alert('Email sudah diubah')
          window.location = "/dashboard"
        }
      })
      .catch((err) => {
        console.log('err', err.message)
        alert('gagal, cek lagi email apakah sudah benar')
      })
  }

  return (
    <div >
      <form onSubmit={handleUpdateEmail} className='d-flex flex-column gap-2'>
        <FloatingLabel label='Email lama'>
          <input type="email" className="form-control fw-bold" placeholder="Email Lama" name="oldEmail" autoComplete="Off" required />
        </FloatingLabel>
        <FloatingLabel label='Email Baru'>
          <input type="email" className="form-control fw-bold" placeholder="Email Lama" name="newEmail" autoComplete="Off" required />
        </FloatingLabel>
        <button type='submit' className='btn btn-primary mt-2 w-100'>UPDATE</button>
      </form>
    </div>
  )
}

export default UpdateEmail
