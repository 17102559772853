import React from 'react'
import { months } from '../../../Components/Utility'

const JOK = ({ filterDataPerformanceByNik }) => {

  const dataJOKByMonth = months.map((date) => {
    const data = filterDataPerformanceByNik('jok', date)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const total = dataJOKByMonth.reduce((acc, value) => {
    return value !== '-' ? acc + parseFloat(value) : acc
  }, 0)

  const formattedTotal = total !== 0 ? total.toLocaleString('id-ID', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'

  return (
    <tbody>
      <tr className='text-center'>
        <td className='fw-bold'>JOK</td>
        {dataJOKByMonth.map((value, i) => (
          <td key={i}>{value !== '-' ? value.toLocaleString('id-ID', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : value}</td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{formattedTotal !== '-' ? `${formattedTotal} jam` : '-'}</td>
      </tr>
    </tbody>
  )
}

export default JOK