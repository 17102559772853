import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useRef, useState } from 'react'
import InputReminder from '../Reminder/input'
import { dateFormated, getRemainingTimeReminder, Loaders } from '../Utility/index';
import { deleteReminder } from '../Reminder/delete';
import { Tooltip } from 'react-tooltip';
import UpdateReminder from '../Reminder/update';

const Reminder = ({ isAdmin }) => {

  const [dataReminder, setDataReminder] = useState([])
  const [selectedReminder, setSelectedReminder] = useState(null)
  const [showFormInput, setShowFormInput] = useState(false)
  const [showFormUpdate, setShowFormUpdate] = useState(false)
  const [admin, setAdmin] = useState('')
  const formInputReminderRef = useRef(null)
  const formUpdateReminderRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [showAll, setShowAll] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const handleClickOutSide = (e) => {
    if (formInputReminderRef.current && !formInputReminderRef.current.contains(e.target)) {
      setShowFormInput(false)
    } else if (formUpdateReminderRef.current && !formUpdateReminderRef.current.contains(e.target)) {
      setShowFormUpdate(false)
    }
  }

  useEffect(() => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_URL}/reminder`)
        .then((res) => {
          setDataReminder(res.data.dataReminder);
        }),
      axios.get(`${process.env.REACT_APP_URL}/users/token`, { withCredentials: true })
        .then((res) => {
          const decoded = jwtDecode(res.data.token)
          setAdmin(decoded.username)
          setLoading(false)
        })
        .catch((err) => console.log('err', err.massage))
    ])

    document.addEventListener("mousedown", handleClickOutSide)
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide)
    }


  }, [])

  useEffect(() => {
    const checkRemindersForTomorrow = () => {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);

      dataReminder.forEach((item) => {
        const targetDate = new Date(item.date);
        targetDate.setHours(0, 0, 0, 0);

        if (targetDate.getTime() === tomorrow.getTime()) {
          alert(`Agenda "${item.reminder}" akan jatuh tempo besok!`);
        }
      });
    };

    if (dataReminder.length > 0) {
      checkRemindersForTomorrow();
    }
  }, [dataReminder]);

  const selectedDataReminders = dataReminder
    .filter((item) => {
      if (showAll) return true;
      const targetDate = new Date(item.date);
      targetDate.setHours(0, 0, 0, 0);
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return targetDate >= now;
    })
    .filter((item) => item.reminder.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <>
      {loading ? (
        <Loaders />
      ) : (
        <div>
          <div className='d-flex flex-row justify-content-between align-items-center'>
            <div className='container title-h1 fs-1' style={{ flex: 1 }}>Reminder</div>
            <input
              type='text'
              className='form-control'
              style={{ width: '25%' }}
              placeholder='Cari agenda'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='d-flex align-items-center p-2 rounded me-2' style={{ flex: 1, justifyContent: 'flex-end' }}>
              <input
                type='checkbox'
                className='form-check-input'
                checked={showAll}
                onChange={(e) => setShowAll(e.target.checked)}
              />
              <label className='title-h1 ms-2 text-decoration-underline'>Tampilkan semua </label>
            </div>
          </div>

          <div className='d-flex flex-wrap gap-2 justify-content-center align-items-center container animate__animated animate__fadeInUp mt-3 mb-3'>
            {selectedDataReminders.length === 0 ? (
              <div className='fs-2 title-h1 text-success text-center d-flex justify-content-center align-items-center'>Tidak ada yg perlu diingat 🤪</div>
            ) : (
              selectedDataReminders.map((items, i) => {
                const { id_reminder, reminder, date, notes } = items;

                return (
                  <div key={i} className='border rounded border-dark p-3 bg-light d-flex flex-column justify-content-center position-relative w-50 gap-1' style={{ height: "200px", flex: '0 0 calc(50% - 1rem)' }}>
                    {isAdmin && (

                      <i className="bi bi-pencil-square position-absolute top-0 fs-5 end-0 p-1 me-4"
                        data-tooltip-id="update-reminder-tooltip" data-tooltip-content="update reminder"
                        onClick={() => {
                          setSelectedReminder(items);
                          setShowFormUpdate(!showFormUpdate);
                        }}
                      ></i>
                    )}
                    {isAdmin && (
                      <i className="bi bi-x-lg position-absolute end-0 top-0 p-1"
                        data-tooltip-id="delete-reminder-tooltip" data-tooltip-content="Hapus reminder"
                        onClick={() => deleteReminder({ id_reminder })}
                      ></i>
                    )}
                    <Tooltip id="update-reminder-tooltip" place="bottom" />
                    <Tooltip id="delete-reminder-tooltip" className="bg-danger" place="bottom" />
                    <div className='title-h1 fs-4'>Agenda : <span className='ms-2'>{reminder}</span></div>
                    <div className='title-h1 fs-4'>Tanggal :
                      <span className='ms-2 me-2'>{dateFormated(date)}</span>
                    </div>
                    <i className='title-h1 fs-5'>Tenggat waktu :
                      <span className='text-danger ms-2'>{getRemainingTimeReminder(date)}</span></i>
                    <div className='fw-bold ms-3'>* <span className='text-secondary'>{notes}</span></div>
                  </div>
                )
              })
            )}
          </div>
          <div className='detail-employee'>
            {isAdmin && (
              <i className="bi bi-plus-lg fs-1"
                onClick={() => setShowFormInput(!showFormInput)}
                data-tooltip-id="input-reminder-tooltip" data-tooltip-content="input reminder"
              ></i>
            )}
            <Tooltip id='input-reminder-tooltip' />
          </div>
          {showFormInput && (
            <InputReminder formInputReminderRef={formInputReminderRef} admin={admin} />
          )}
          {showFormUpdate && (
            <UpdateReminder formUpdateReminderRef={formUpdateReminderRef} admin={admin} selectedReminder={selectedReminder} />
          )}
        </div>
      )}
    </>
  )
}

export default Reminder
