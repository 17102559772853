import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import HOK from './Absensi/HOK'
import JOK from './Absensi/JOK'
import Kpi from './KPI'
import DailyReport from './DR'
import Sick from './Absensi/Sick'
import Late from './Absensi/Late'
import Absent from './Absensi/Absent'
import Permission from './Absensi/Permission'
import GoHome from './Absensi/Go Home'
import Evaluation from './Evaluation'

const Performance = () => {

  const [dataPerformanceByNik, setDataPerformanceByNik] = useState([])
  const { nik } = useParams('')

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/performance/${nik}`)
      .then((res) => setDataPerformanceByNik(res.data.dataPerformanceByNik))
      .catch((err) => console.log(err))
  }, [nik])

  const filterDataPerformanceByNik = (category, periode) => {
    return dataPerformanceByNik.filter(items => {
      const matchCategory = category ? items.category.toLowerCase() === category.toLowerCase() : true
      const matchPeriode = periode ? items.periode.toLowerCase() === periode.toLowerCase() : true
      return matchCategory && matchPeriode;
    })
  }

  return (
    <>
      <div className='container mt-3 border rounded animate__animated animate__fadeInDown'>
        <div className='title-h1 fs-1 text-center'>Penilaian</div>
        <table className='table table-bordered border-dark mt-1 mb-4'>
          <thead className='table-warning table-bordered border-dark'>
            <tr className='text-center fs-5'>
              <th>Category</th>
              <th>Jan</th>
              <th>Feb</th>
              <th>Mar</th>
              <th>Apr</th>
              <th>Mei</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Agust</th>
              <th>Sept</th>
              <th>Okt</th>
              <th>Nov</th>
              <th>Des</th>
              <th>Total/Average</th>
            </tr>
          </thead>
          <HOK filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <JOK filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <Late filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <Absent filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <Permission filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <Sick filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <GoHome filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <Kpi filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <DailyReport filterDataPerformanceByNik={filterDataPerformanceByNik} />
          <Evaluation filterDataPerformanceByNik={filterDataPerformanceByNik} />
        </table>
      </div>
    </>
  )
}

export default Performance