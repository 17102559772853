import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const FormRekapPerformance = ({ formRekapRef }) => {

  const navigate = useNavigate()

  const [dataPerformance, setDataPerformance] = useState([])
  const [dataEmployees, setDataEmployees] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/performance`)
      .then((res) => setDataPerformance(res.data.dataPerformance))
      .catch((err) => console.log('err', err))
    axios.get(`${process.env.REACT_APP_URL}/employees`)
      .then((res) => setDataEmployees(res.data.employee))
      .catch((err) => console.log('err employee', err))
  }, [])

  const dataLabel = [
    { label: 'ADITAMA ENERGI', text: 'info', navigate: 'AE' },
    { label: 'BARA KUMALA', text: 'danger', navigate: 'BK' },
    { label: 'SINET', text: 'success', navigate: 'SN' },
  ]

  return (
    <div className="border border-2 rounded bg-body-secondary shadow-sm w-25 fs-4 p-2 mt-5 me-5 position-fixed end-0" style={{ zIndex: '100' }} ref={formRekapRef}>
      {dataLabel.map((item, i) => {
        return (
          <div key={i}
            className={`title-h1 ms-2 text-${item.text}`}>
            {`${item.label}`}
            <i className='bi bi-chevron-right ms-5 text-dark'
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/rekapPerformance${item.navigate}`, { state: { dataPerformance: dataPerformance, dataEmployees: dataEmployees } })}>
            </i>
          </div>
        )
      })}
    </div>
  )
}

export default FormRekapPerformance