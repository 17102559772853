import React from 'react'
import { useLocation } from 'react-router-dom'
import logoAE from '../../Assets/Logo/Aditama.png'
import NavigationBar from '../../Components/Utility/Navbar'
import { getRemainingTimeLicense } from '../../Components/Utility'

const RekapLicenseAE = ({ title }) => {

  const location = useLocation()
  const dataEmployees = location.state.dataEmployees
  const dataDrivingLicense = location.state.dataDrivingLicense

  const dataEmployee = dataEmployees.filter(item => item.unit.toLowerCase() === 'aditama energi' && item.status.toLowerCase() === 'aktif')
  const dataLicense = dataDrivingLicense.filter(item => item.unit.toLowerCase() === 'aditama energi')

  const dataCombined = dataEmployee.map(employee => {
    const license = dataLicense.find(license => license.nik === employee.nik)
    return license ? { ...employee, ...license } : null
  }).filter(item => item !== null)

  const dataLabel = [
    'No.', 'NIK', 'Nama', 'Jabatan', 'No SIM', 'Jenis', 'Berlaku s/d', 'Wilayah', 'Sisa'
  ]

  const dateFormated = (date) => {
    if (!date) return '-'
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return new Date(date).toLocaleDateString('id-ID', options)
  }

  return (
    <>
      <NavigationBar logoAE={logoAE} title={title} />
      <div className='mt-2 ms-3 me-3 animate__animated animate__fadeInDown'>
        <table className='table table-bordered border-dark mt-3'>
          <thead className='table-primary table-bordered border-dark sticky-header'>
            <tr className='text-center'>
              {dataLabel.map((label, i) => (
                <th key={i}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataCombined.map((item, j) => (
              <tr key={j}>
                <td className='text-center'>{j + 1}.</td>
                <td>{item.nik}</td>
                <td>{item.nama_lengkap}</td>
                <td>{item.jabatan}</td>
                <td>{item.no_license || '-'}</td>
                <td>{item.type || '-'}</td>
                <td>{dateFormated(item.date_expired) || '-'}</td>
                <td>{item.region || '-'}</td>
                <td>{getRemainingTimeLicense(item.date_expired)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default RekapLicenseAE
