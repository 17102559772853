import React from 'react'
import { FloatingLabel } from 'react-bootstrap'
import axios from 'axios'

const AddUser = ({ formAddUserRef }) => {

  const handleFormAddUser = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formAddUser = Object.fromEntries(formData)

    axios.post(`${process.env.REACT_APP_URL}/users`, {
      username: formAddUser.userName,
      email: formAddUser.email,
      password: formAddUser.password,
      confPassword: formAddUser.confPassword,
      status: formAddUser.status
    })
      .then((res) => {
        console.log(res)
        alert('Berhasil menambah USER !!!')
      }).catch((err) => {
        console.log(err)
        alert('Gagal menambahkan USER !!!')
      })
  }

  return (
    <div className="card p-4 bg-success-subtle position-absolute shadow" style={{
      top: "32px", right: "75px", width: "350px"
    }}>
      <h2 className="border-bottom border-2 border-black mb-3">Tambah USER</h2>
      <form
        ref={formAddUserRef}
        onSubmit={handleFormAddUser}
        className="d-flex flex-column gap-1 mt-3">
        <FloatingLabel label='Username/nama'>
          <input type="text" className="form-control fw-bold" placeholder="Username/nama" name="userName"
            minLength={3} required />
        </FloatingLabel>
        <FloatingLabel label='Email'>
          <input type="email" className="form-control fw-bold" placeholder="Email" name="email" required />
        </FloatingLabel>
        <FloatingLabel label='Password'>
          <input type="text" className="form-control fw-bold" placeholder="Password" name="password"
            minLength={6} required />
        </FloatingLabel>
        <FloatingLabel label='Confirmasi Password'>
          <input type="text" className="form-control fw-bold" placeholder="Confirmasi Password" name="confPassword"
            minLength={6} required />
        </FloatingLabel>
        <FloatingLabel label='Status'>
          <input type="text" className="form-control fw-bold" placeholder="status" name="status" required />
        </FloatingLabel>
        <button type="submit" className="btn btn-dark fw-bold mt-3">SAVE</button>
      </form>
    </div >
  )
}

export default AddUser