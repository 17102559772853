import React from 'react'
import logoBKG from '../../Assets/Logo/BKG.png'
import logo from '../../Assets/Logo/logo2.png'
import { useNavigate } from 'react-router-dom'
import ReactTypingEffect from "react-typing-effect"
import 'animate.css'

const Home = ({ title, description }) => {

  const navigate = useNavigate()

  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
      <img src={logo} alt='logo' className='logo2-home position-absolute top-0 start-0 me-5 mt-2 ms-5 logo' />
      <h5 onClick={() => navigate('/login')}
        className='position-absolute top-0 end-0 me-5 mt-3 btn btn-info fs-5'
      >LOGIN</h5>
      <img src={logoBKG} alt='logoBKG' className='p-5 border-end border-2 border-black animate__animated animate__pulse' />
      <section className='p-1 w-50 '>
        <ReactTypingEffect
          text={[title, description]}
          className='ms-5 fs-2 '
          speed={100}
          eraseDelay={300}
          eraseSpeed={100}
          typingDelay={100}
        />
      </section >
    </div >
  )
}

export default Home