import React from 'react'
import { dateFormated } from './../Components/Utility/index';

const WarningLetter = ({ dataWarningLetterByNik }) => {

  const labelWarningLetter = [
    { label: 'No.' },
    { label: 'No Surat' },
    { label: 'Tahun' },
    { label: 'Jenis SP' },
    { label: 'Tanggal Surat' },
    { label: 'Tanggal Berlaku', colspan: 2 },
    { label: 'Sisa Hari Berlaku' },
    { label: 'Keterangan SP' },
  ]

  return (
    <div className='container card mt-2 animate__animated animate__fadeInDown'>
      <div className='title-h1 fs-1 text-center'>Surat Peringatan</div>
      <table className='table table-bordered table-striped-danger border-dark table-hover mt-1'>
        <thead className='table-danger table-bordered border-dark'>
          <tr className='text-center fw-bold'>
            {labelWarningLetter.map((item, i) => (
              <th key={i} className=' fs-5' colSpan={item.colspan || 1}>{item.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className=' border-bordered border-dark'>
          {dataWarningLetterByNik.map((items, j) => {

            const remainingDays = (new Date(items.date_end) - new Date()) / (1000 * 60 * 60 * 24)
            const remainingWarningLetter = remainingDays >= 0 ? `${Math.round(remainingDays)} Hari` : 'SP Telah Berakhir';

            return (
              <tr key={j} className='text-center'>
                <td className='fw-bold'>{j + 1}.</td>
                <td>{items.no_letter}</td>
                <td>{items.year}</td>
                <td>{items.type}</td>
                <td>{dateFormated(items.date_letter)}</td>
                <td>{dateFormated(items.date_start)}</td>
                <td>{dateFormated(items.date_end)}</td>
                <td className='fw-bold'>{remainingWarningLetter}</td>
                <td>{items.info}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default WarningLetter