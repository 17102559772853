import axios from 'axios';
import React from 'react';
import { FloatingLabel } from 'react-bootstrap';

const UpdatePassword = ({ username }) => {

  const handleFormPassword = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget)
    const formPassword = Object.fromEntries(formData)

    axios.put(`${process.env.REACT_APP_URL}/users`, {
      username,
      password: formPassword.oldPassword,
      new_password: formPassword.newPassword,
      confPassword: formPassword.confPassword,
    }).then(() => {
      alert('berhasil mengganti password ...!')
      window.location = "/login"
    }).catch((err) => {
      if (err.response.data.msg === 'password wrong') {
        alert('password salah coba lagi')
      } if (err.response.data.msg === 'new password and confPassword not match') {
        alert('password baru dan confirmasi password tidak sama')
      } else {
        console.log("eror", err)
      }
    })
  }
  return (
    <div>
      <form
        onSubmit={handleFormPassword}>
        <div className="d-flex flex-column gap-1">
          <FloatingLabel label='Password Lama'>
            <input type="text" className="form-control fw-bold" placeholder="Password Lama" name="oldPassword" autoComplete="Off" required />
          </FloatingLabel>
          <FloatingLabel label='Password Baru'>
            <input type="text" className="form-control fw-bold" placeholder="Password Baru" name="newPassword" autoComplete="Off" minLength={6} required />
          </FloatingLabel>
          <FloatingLabel label='Confirmasi Password Baru'>
            <input type="text" className="form-control fw-bold" placeholder="Confirmasi Password Baru" name="confPassword" autoComplete="Off" minLength={6} required />
          </FloatingLabel>
          <button type="submit" className="btn btn-dark mt-2">UPDATE</button>
        </div>
      </form>
    </div>
  )
}

export default UpdatePassword
