import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react';
import { Loaders } from '..';
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const timerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const startLogoutTime = () => {
    clearTimeout(timerRef.current);

    const fiveDays = 5 * 24 * 60 * 60 * 1000;

    timerRef.current = setTimeout(() => {
      axios.delete(`${process.env.REACT_APP_URL}/users/logout`, { withCredentials: true })
        .then(() => {
          alert('waktu sesi anda telah habis , silahkan login kembali');
          window.location.replace('/login');
        })
        .catch((err) => console.log('err', err.message))
    }, fiveDays);
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/users/token`, { withCredentials: true })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err.message)
        if (err) {
          alert('Anda Belum login atau sesi anda habis , Login dulu ...!')
          window.location.replace('/login')
        }
      })

    startLogoutTime();

    const resetTimer = () => startLogoutTime();
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    }
  }, [navigate])

  if (loading) return <Loaders />

  return <Component {...rest} />
}

export default PrivateRoute;
