import React, { useRef } from 'react'

const UpdateEvaluation = ({ getMonthlyData, handleFormValue }) => {

  const formRef = useRef(null)

  const quarterMonths = [
    '2024-03-01',
    '2024-06-01',
    '2024-09-01',
    '2024-12-01',
  ]

  const dataPerformance = getMonthlyData('nilai', quarterMonths)
  const [dataMar,
    dataJun,
    dataSept,
    dataDes,
  ] = dataPerformance

  const monthlyData = [
    { month: 'Mar', data: dataMar },
    { month: 'Jun', data: dataJun },
    { month: 'Sept', data: dataSept },
    { month: 'Des', data: dataDes }
  ]

  return (
    <div className='container'>
      <form ref={formRef} className='d-flex justify-content-center'>
        <div className='p-2 bg-light m-2 rounded border border-dark d-flex flex-column'>
          <div className='title-h1 fs-5 text-center fs-3'>Nilai Atasan</div>
          {monthlyData.map(({ month, data }, i) => (
            <div key={i} className='d-flex gap-4 align-items-center border-bottom'>
              <label>{month}</label>
              <input
                type='text'
                className='input-underlined'
                name={`id${month}`}
                defaultValue={data.map(item => item.id)}
                style={{ display: 'none' }}
              />
              <input type='text' name='category' defaultValue='nilai Atasan' style={{ display: 'none' }} />
              <input
                type='decimal'
                className='input-underlined'
                name={`value${month}`}
                defaultValue={data.map(item => item.value)}
              />
              <button type='submit' className='btn fw-bold'
                onClick={(e) => handleFormValue(e, month, formRef)}
              >
                <i className="bi bi-check-lg"></i>
              </button>
            </div>
          ))}
        </div>
      </form>
    </div>
  )
}

export default UpdateEvaluation