import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend)

const ChartEvaluation = ({ dataPerformance }) => {

  const [filteredData, setFilteredData] = useState({})

  useEffect(() => {
    const dataHok = dataPerformance.filter(performance => {
      const year = new Date(performance.periode).getFullYear()
      return performance.category.toLowerCase() === 'nilai' && year === 2024
    })

    const getQuarter = (month) => {
      if (month <= 2) return 1
      if (month <= 5) return 2
      if (month <= 8) return 3
      return 4
    }

    const totalPerUnit = dataHok.reduce((acc, performance) => {
      const unit = performance.unit.toLowerCase()
      const month = new Date(performance.periode).getMonth()
      const quarter = getQuarter(month)

      if (!acc[unit]) acc[unit] = {}
      if (!acc[unit][quarter]) acc[unit][quarter] = { total: 0, count: 0 }

      acc[unit][quarter].total += Number(performance.value)
      acc[unit][quarter].count += 1

      return acc
    }, {})

    const averagePerUnit = Object.fromEntries(
      Object.entries(totalPerUnit).map(([unit, quarterlyTotals]) => [
        unit,
        Object.fromEntries(
          Object.entries(quarterlyTotals).map(([quarter, { total, count }]) => [
            quarter,
            count ? total / count : 0,
          ])
        ),
      ])
    )

    setFilteredData(averagePerUnit)

  }, [dataPerformance])

  const unitColors = {
    'aditama': { backgroundColor: 'rgba(13, 202, 240, 0.7)', borderColor: 'rgba(13, 202, 240, 1)' },
    'bara kumala': { backgroundColor: 'rgba(220, 53, 69, 0.7)', borderColor: 'rgba(220, 53, 69, 1)' },
    'sinet': { backgroundColor: 'rgba(25, 135, 84, 0.7)', borderColor: 'rgba(25, 135, 84, 1)' },
  }

  const chartData = {
    labels: ['Q1', 'Q2', 'Q3', 'Q4'],
    datasets: Object.entries(filteredData).map(([unit, quarterlyAverages], index) => ({
      label: unit.toUpperCase(),
      data: [1, 2, 3, 4].map(quarter => quarterlyAverages[quarter] || 0), // Data untuk masing-masing kuartal
      backgroundColor: unitColors[unit]?.backgroundColor || `rgba(${index * 30}, ${index * 50}, 200, 0.7)`,
      borderColor: unitColors[unit]?.borderColor || `rgba(${index * 30}, ${index * 50}, 200, 1)`,
      borderWidth: 1,
    }))
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Rata-rata Nilai Atasan',
      },
    },
  }

  return (
    <div className='container w-75'>
      <Bar data={chartData} options={chartOptions} />
    </div>
  )
}

export default ChartEvaluation
