import React from 'react'

const Search = ({ searchValue, setSearchValue, suggestionSearch }) => {

  return (
    <>
      <input className='form-control search fw-bold focus-ring focus-ring-light shadow shadow-sm'
        placeholder='Search...'
        value={searchValue}
        list='suggestions'
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <i className="bi bi-search"></i>
      <datalist id='suggestions' >
        {suggestionSearch.map((suggestion, i) => (
          <option key={i} value={suggestion} />
        ))}
      </datalist>
    </>
  )
}

export default Search