import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from './Components/Home';
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard"
import DetailEmployee from './DataEmployee/Detail'
import DataEmployee from './DataEmployee'
import UpdateDataEmployee from './DataEmployee/Update/'
import AddEmployee from './DataEmployee/Update/AddEmployee'
import DataEmployeeAE from "./DataEmployee/AE"
import DataEmployeeBK from "./DataEmployee/BK"
import DataEmployeeSN from "./DataEmployee/SN"
import InputPerformance from "./Performance/Input"
import RekapVacationAE from "./Vacation/Rekap/AE"
import RekapVacationBK from "./Vacation/Rekap/BK"
import RekapVacationSN from "./Vacation/Rekap/SN"
import InputDataEmployee from "./DataEmployee/Input"
import RekapLicenseAE from "./DrivingLicense/Rekap/AE"
import RekapLicenseBK from "./DrivingLicense/Rekap/BK"
import RekapLicenseSN from "./DrivingLicense/Rekap/SN"
import RekapPerformanceAE from "./Performance/Rekap/AE";
import RekapPerformanceBK from "./Performance/Rekap/BK";
import RekapPerformanceSN from "./Performance/Rekap/SN";
import NotFound from "./Components/Utility/NotFound";
import PrivateRoute from "./Components/Utility/PrivateRoute";
import Email from "./Users/Email";
import Verify from './Users/Email/Verify';
import ResetPassword from "./Users/Email/ResetPassword";
import './Global.css'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home title='Selamat Datang di Database BKG' description='Harap gunakan Data dengan Bijak...!' />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-email" element={<Email />} />
        <Route path="/verify-code" element={<Verify />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/dataEmployee" element={<PrivateRoute element={DataEmployee} />} />
        <Route path="/dataEmployee/aditama" element={<PrivateRoute element={DataEmployeeAE} title="Data Karyawan" />} />
        <Route path="/dataEmployee/baraKumala" element={<PrivateRoute element={DataEmployeeBK} title="Data Karyawan" />} />
        <Route path="/dataEmployee/sinet" element={<PrivateRoute element={DataEmployeeSN} title="Data Karyawan" />} />
        <Route path="/detailEmployee/:nik" element={<PrivateRoute element={DetailEmployee} />} />
        <Route path="/addEmployee" element={<PrivateRoute element={AddEmployee} title="Input Data Karyawan" />} />
        <Route path="/input" element={<PrivateRoute element={InputDataEmployee} title="Input Data Karyawan" />} />
        <Route path="/inputPerformance" element={<PrivateRoute element={InputPerformance} />} />
        <Route path="/detailEmployee/updateDataEmployee" element={<PrivateRoute element={UpdateDataEmployee} title="Update Data Karyawan" />} />
        <Route path="/dataVacation/AE" element={<PrivateRoute element={RekapVacationAE} title="Rekap Cuti" />} />
        <Route path="/dataVacation/BK" element={<PrivateRoute element={RekapVacationBK} title="Rekap Cuti" />} />
        <Route path="/dataVacation/SN" element={<PrivateRoute element={RekapVacationSN} title="Rekap Cuti" />} />
        <Route path="/dataLicense/AE" element={<PrivateRoute element={RekapLicenseAE} title="Rekap SIM Polisi" />} />
        <Route path="/dataLicense/BK" element={<PrivateRoute element={RekapLicenseBK} title="Rekap SIM Polisi" />} />
        <Route path="/dataLicense/SN" element={<PrivateRoute element={RekapLicenseSN} title="Rekap SIM Polisi" />} />
        <Route path="/rekapPerformanceAE" element={<PrivateRoute element={RekapPerformanceAE} title="Rekap Kinerja Karyawan" />} />
        <Route path="/rekapPerformanceBK" element={<PrivateRoute element={RekapPerformanceBK} title="Rekap Kinerja Karyawan" />} />
        <Route path="/rekapPerformanceSN" element={<PrivateRoute element={RekapPerformanceSN} title="Rekap Kinerja Karyawan" />} />
      </Routes>
    </Router>
  )
}

export default App;
