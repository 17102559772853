import React from 'react'

const ToggleSwitch = ({ isOn, setIsOn, tooltipId, tooltipContent }) => {

  const handleToggle = () => {
    setIsOn(!isOn)
  }

  return (
    <div onClick={handleToggle}
      style={{ cursor: 'pointer', fontSize: '2rem', display: 'flex', alignItems: 'center' }}
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipContent}>
      <i className={`bi ${isOn ? 'bi-toggle-off' : 'bi-toggle-on'} ms-2`} ></i>
    </div>
  )
}

export default ToggleSwitch