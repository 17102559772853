import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import NavigationBar from '../../Components/Utility/Navbar'

const InputPerformance = () => {

  const location = useLocation()
  const dataEmployees = location.state.dataEmployees

  const filterEmployeesByCriteria = (unit, nama_lengkap, status) => {
    return dataEmployees.filter(employee => {
      const matchUnit = unit ? employee.unit.toLowerCase() === unit.toLowerCase() : true
      const matchName = nama_lengkap ? employee.nama_lengkap.toLowerCase().includes(nama_lengkap.toLowerCase()) : true
      const matchStatus = status ? true : employee.status.toLowerCase() === 'aktif'
      return matchUnit && matchName && matchStatus;
    })
  }

  const [selectedUnit, setSelectedUnit] = useState('')
  const [searchName, setSearchName] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('hok')
  const [showStatus, setShowStatus] = useState(false)

  const nameUnitForAlert = (category) => {
    const categoryMap = {
      hok: 'HOK',
      jok: 'JOK',
      tlt: 'Terlambat',
      mkr: 'Mangkir',
      izn: 'Izin',
      skt: 'Sakit',
      plg: 'Pulang Cepat',
      kpi: 'Kpi',
      dr: 'Daily Report',
      nilai: 'Atasan'
    }
    return categoryMap[category]
  }

  const filteredEmployee = filterEmployeesByCriteria(selectedUnit, searchName, showStatus)

  const getPreviousMonthYear = () => {
    const today = new Date()
    today.setMonth(today.getMonth() - 1)
    const year = today.getFullYear()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    return `${year}-${month}`
  }

  const [datePeriodePerformance, setDatePeriodePerformance] = useState(getPreviousMonthYear())
  const [submittedNikPeriode, setSubmittedNikPeriode] = useState('')
  const [dataPerformance, setDataPerformance] = useState([])
  const admin = localStorage.getItem('username')

  const filterDataPerformance = (nik, category, periode, unit) => {
    return dataPerformance.filter(items => {
      const matchNik = nik ? items.nik === nik : true
      const matchCategory = category ? items.category.toLowerCase() === category.toLowerCase() : true
      const matchUnit = unit ? items.unit.toLowerCase() === unit.toLowerCase() : true
      const matchPeriode = periode ? items.periode.toLowerCase() === periode.toLowerCase() : true
      return matchNik && matchUnit && matchCategory && matchPeriode;
    })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/performance`)
      .then((res) => {
        const nikPeriodeList = res.data.dataPerformance.map(item => `${item.nik}-${item.periode}`)
        setSubmittedNikPeriode(nikPeriodeList)
        setDataPerformance(res.data.dataPerformance)
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  const handleFormPerformance = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formPerformance = Object.fromEntries(formData)

    const reqData = {
      nik: formPerformance.nik,
      unit: formPerformance.unit,
      nama_lengkap: formPerformance.name,
      category: selectedCategory,
      periode: datePeriodePerformance,
      value: formPerformance.valueCategory,
      updated_by: admin
    }

    axios.post(`${process.env.REACT_APP_URL}/performance`, reqData)
      .then((res) => {
        if (res.data.dataPerformance) {
          alert(`berhasil input nilai ${nameUnitForAlert(selectedCategory)} ${formPerformance.name}`)
          setSubmittedNikPeriode(prevState => [...prevState, `${formPerformance.nik}-${datePeriodePerformance}`])
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <NavigationBar setDatePeriodePerformance={setDatePeriodePerformance} getPreviousMonthYear={getPreviousMonthYear} />
      <div className='d-flex flex-column container mt-2 bg-body-secondary rounded animate__animated animate__fadeInDown'>
        <div className='d-flex align-items-center justify-content-between w-100 gap-2 mt-2'>
          <div className='d-flex flex-column w-50'>
            <div className='title-h1 fs-4'>KATEGORI</div>
            <select className='form-select' onChange={(e) => setSelectedCategory(e.target.value)}>
              <option value='hok' selected>HOK</option>
              <option value='jok'>JOK</option>
              <option value='tlt'>Terlambat</option>
              <option value='mkr'>Mangkir</option>
              <option value='izn'>Izin</option>
              <option value='skt'>Sakit</option>
              <option value='plg'>Pulang Cepat</option>
              <option value='kpi'>KPI</option>
              <option value='dr'>Daily Report</option>
              <option value='nilai'>Nilai Atasan</option>
            </select>
          </div>
          <div className='d-flex flex-column'>
            <div className='title-h1 fs-4'>UNIT</div>
            <select className='form-select form-select-lg' onChange={(e) => setSelectedUnit(e.target.value)} >
              <option value="" >Semua Unit</option>
              <option value="ADITAMA ENERGI">ADITAMA ENERGI</option>
              <option value="BARA KUMALA">BARA KUMALA</option>
              <option value="SINET">SINET</option>
            </select>
          </div>
        </div>
        <hr />
        <div className='d-flex justify-content-between align-items-center'>
          <div className='form-check ' onChange={() => setShowStatus(!showStatus)}>
            <input type='checkbox' className='form-check-input' defaultChecked={showStatus} />
            <label className='form-check-label text-decoration-underline fw-bold text-danger'>NON AKTIF</label>
          </div>
          <input type='text' className='input-underlined w-25' placeholder='Cari Nama Karyawan....' onChange={(e) => setSearchName(e.target.value)} />
        </div>
        {filteredEmployee.map((employee, i) => {
          const nikPeriode = `${employee.nik}-${datePeriodePerformance}`
          const filterValuePerformance = filterDataPerformance(employee.nik, selectedCategory, `${datePeriodePerformance}-01`)
          const valuePerformance = filterValuePerformance.map(items => items.value)
          const isValuePerformance = valuePerformance.length >= 1;
          return (
            <form key={i} className='bg-light p-2 rounded mt-2 shadow-sm' onSubmit={handleFormPerformance}>
              <div className='d-flex justify-content-center  rounded align-items-center gap-2 p-2'>
                <div className='d-flex flex-column w-25'>
                  <label className='title-h1'>NIK</label>
                  <input type='number' name='nik' className='input-underlined' value={employee.nik} readOnly />
                </div>
                <div className='d-flex flex-column w-25'>
                  <label className='title-h1'>Status</label>
                  <input type='text' name='status' className='input-underlined' value={employee.status} readOnly />
                </div>
                <div className='d-flex flex-column w-25'>
                  <label className='title-h1'>Unit</label>
                  <input type='text' className='input-underlined' name='unit' value={employee.unit} />
                </div>
                <div className='d-flex flex-column w-25' >
                  <lab className='title-h1'>Nama Lengkap</lab>
                  <input type='text' name='name' className='input-underlined' value={employee.nama_lengkap} readOnly />
                </div>
                <div className='d-flex flex-column'>
                  <label className='title-h1'>Nilai</label>
                  <input type='decimal' name='valueCategory' className='input-underlined' required placeholder={valuePerformance.length > 0 ? valuePerformance : 'Masukan Nilai'} />
                </div>
                {!submittedNikPeriode.includes(nikPeriode) && !isValuePerformance && (
                  <button type='submit' className='btn text-primary mt-3'>
                    <i className='bi bi-check-lg fw-bold fs-2'></i>
                  </button>
                )}
              </div>
            </form>
          )
        })}
      </div >
    </>
  )
}

export default InputPerformance