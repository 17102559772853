import React, { useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import logoAE from '../../Assets/Logo/Aditama.png'
import logoBK from '../../Assets/Logo/BK.png'
import logoSN from '../../Assets/Logo/SINET.png'
import { countAge, dateFormated, isValueEmpty } from './../Utility/index'
import { Tooltip } from 'react-tooltip'

const PkwtDashboard = ({ dataEmployees }) => {

  const [dateEndPkwt, setDateEndPkwt] = useState('')

  const filterEmployeesByCriteria = (unit, status, ikatan_kerja) => {
    return dataEmployees.filter(employee => {
      const matchStatus = status ? employee.status.toLowerCase() === status.toLowerCase() : true;
      const matchUnit = unit ? employee.unit.toLowerCase() === unit.toLowerCase() : true;
      const matchPkwt = ikatan_kerja ? employee.ikatan_kerja.toLowerCase() === ikatan_kerja.toLowerCase() : true;
      return matchStatus && matchUnit && matchPkwt;
    })
  }

  const employeeAktive = filterEmployeesByCriteria(null, 'aktif')

  const isDefaultDate = (date) => {
    return date === null || isNaN(Date.parse(date));
  }

  useEffect(() => {
    const getEndOfMonth = () => {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const lastDayOfMonth = new Date(year, month, 0).getDate()
      return `${year}-${month.toString().padStart(2, '0')}-${lastDayOfMonth.toString().padStart(2, '0')}`
    }
    setDateEndPkwt(getEndOfMonth())
  }, [])

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value
    const [year, month] = selectedMonth.split('-')
    const lastDayOfMonth = new Date(year, month, 0).getDate()
    setDateEndPkwt(`${year}-${month}-${lastDayOfMonth}`)
  }

  const getCurrentMonthYear = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    return `${year}-${month}`
  }

  const selectedDateEndPkwt = employeeAktive.map(employee => {
    const dates = [
      employee.akhir_pkwt_8,
      employee.akhir_pkwt_7,
      employee.akhir_pkwt_6,
      employee.akhir_pkwt_5,
      employee.akhir_pkwt_4,
      employee.akhir_pkwt_3,
      employee.akhir_pkwt_2,
      employee.akhir_pkwt_1
    ].filter(date => !isNaN(Date.parse(date)) && !isDefaultDate(date))
      .sort((a, b) => new Date(b) - new Date(a))

    const selectedDate = dates.find(date => date === dateEndPkwt)
    const isMostRecent = selectedDate === dates[0];

    return selectedDate && isMostRecent ? { nik: employee.nik, name: employee.nama_lengkap, unit: employee.unit, date: selectedDate } : null;
  }).filter(entry => entry !== null)

  const employeeEndContractAE = selectedDateEndPkwt.filter(entry => entry.unit === 'ADITAMA ENERGI').map(entry => entry.name)
  const tooltipNameEmployeeEndContractAE = (
    <div className='d-flex gap-2'>
      <ul className='fs-5'>
        {employeeEndContractAE.map(name => <li key={name}>{name}</li>)}
      </ul>
    </div>
  )
  const tooltipHtmlNameEmployeeEndContractAE = ReactDOMServer.renderToString(tooltipNameEmployeeEndContractAE)

  const employeeEndContractBK = selectedDateEndPkwt.filter(entry => entry.unit === 'BARA KUMALA').map(entry => entry.name)
  const tooltipNameEmployeeEndContractBK = (
    <div className='d-flex gap-2'>
      <ul className='fs-5'>
        {employeeEndContractBK.map(name => <li key={name}>{name}</li>)}
      </ul>
    </div>
  )
  const tooltipHtmlNameEmployeeEndContractBK = ReactDOMServer.renderToString(tooltipNameEmployeeEndContractBK)

  const employeeEndContractSN = selectedDateEndPkwt.filter(entry => entry.unit === 'SINET').map(entry => entry.name)
  const tooltipNameEmployeeEndContractSN = (
    <div className='d-flex gap-2'>
      <ul className='fs-5'>
        {employeeEndContractSN.map(name => <li key={name}>{name}</li>)}
      </ul>
    </div>
  )
  const tooltipHtmlNameEmployeeEndContractSN = ReactDOMServer.renderToString(tooltipNameEmployeeEndContractSN)

  const ageCategory = employeeAktive.map(employee => ({
    ...employee,
    age: countAge(employee.tanggal_lahir)
  }));

  const employeeRetiring = (ageCategory.filter(employee => employee.age === 59))
  const nameAndDateEmployeeRetiring = employeeRetiring.map(employee => ({
    name: employee.nama_lengkap,
    bornDate: dateFormated(employee.tanggal_lahir)
  }))
  const tooltipContentNameEmployeeRetiring = (
    <div>
      {nameAndDateEmployeeRetiring.map((employee, i) => (
        <div key={i}>
          <li>{employee.name} ( {employee.bornDate} )</li>
        </div>
      ))}
    </div>
  )
  const tooltipHtmlNameEmployeeRetiring = ReactDOMServer.renderToString(tooltipContentNameEmployeeRetiring)

  const employeePermanentAE = filterEmployeesByCriteria('aditama energi', 'aktif', 'tetap')
  const employeeContractAE = filterEmployeesByCriteria('aditama energi', 'aktif', 'kontrak')
  const employeeDailyAE = filterEmployeesByCriteria('aditama energi', 'aktif', 'harian')

  const employeePermanentBK = filterEmployeesByCriteria('bara kumala', 'aktif', 'tetap')
  const employeeContractBK = filterEmployeesByCriteria('bara kumala', 'aktif', 'kontrak')
  const employeeDailyBK = filterEmployeesByCriteria('bara kumala', 'aktif', 'harian')

  const employeePermanentSN = filterEmployeesByCriteria('sinet', 'aktif', 'tetap')
  const employeeContractSN = filterEmployeesByCriteria('sinet', 'aktif', 'kontrak')
  const employeeDailySN = filterEmployeesByCriteria('sinet', 'aktif', 'harian')

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='container mt-2 d-flex gap-2'>
          <div className='bg-light d-flex flex-column p-2 border border-black rounded animate__animated animate__fadeInDown'>
            <div className='d-flex align-items-center gap-5 border-bottom border-2 p-1'>
              <div className='title-h1 text-center'>Daftar Karyawan Yg Akan Habis Kontrak Bulan</div>
              <input type='month' className='form-control w-50' onChange={handleMonthChange} defaultValue={getCurrentMonthYear()} />
            </div>
            <div className='d-flex flex-column fs-3 p-3'>
              {!isValueEmpty(employeeEndContractAE.length) && (
                <li>Aditama Energi : <span style={{ cursor: 'pointer' }} data-tooltip-id='name-employee-endContractAE-tooltip' data-tooltip-html={tooltipHtmlNameEmployeeEndContractAE}>{employeeEndContractAE.length}</span>
                </li>
              )}
              <Tooltip id='name-employee-endContractAE-tooltip' place='bottom-end' />
              {!isValueEmpty(employeeEndContractBK.length) && (
                <li>Bara Kumala : <span style={{ cursor: 'pointer' }} data-tooltip-id='name-employee-endContractBK-tooltip' data-tooltip-html={tooltipHtmlNameEmployeeEndContractBK}>{employeeEndContractBK.length}</span></li>
              )}
              <Tooltip id='name-employee-endContractBK-tooltip' place='bottom-end' />
              {!isValueEmpty(employeeEndContractSN.length) && (
                <li>SINET : <span style={{ cursor: 'pointer' }} data-tooltip-id='name-employee-endContractSN-tooltip' data-tooltip-html={tooltipHtmlNameEmployeeEndContractSN}>{employeeEndContractSN.length}</span></li>
              )}
              <Tooltip id='name-employee-endContractSN-tooltip' place='bottom-end' />
            </div>
          </div>
          <li className='title-h1 ms-3 fs-3' >Karyawan Akan Pensiun : <span className='title-h1' style={{ cursor: "pointer" }} data-tooltip-id='name-employee-retiring-tooltip' data-tooltip-html={tooltipHtmlNameEmployeeRetiring}>{employeeRetiring.length}</span></li>
        </div>
        <Tooltip id='name-employee-retiring-tooltip' place='bottom-end' />
        <div className='d-flex container mt-2 gap-3'>
          <div className='card d-flex flex-column flex-grow-1 bg-light shadow shadow-sm animate__animated animate__slideInLeft' style={{ zIndex: '-1' }}>
            <div className='border-bottom border-2 p-2 text-center w-100'>
              <img src={logoAE} alt='logo-AE' className='logo-dashboard' />
            </div>
            <ul className='fs-5'>
              {!isValueEmpty(employeePermanentAE.length) && (
                <li>Tetap : {employeePermanentAE.length}</li>
              )}
              <li>Kontrak : {employeeContractAE.length}</li>
              {!isValueEmpty(employeeDailyAE.length) && (
                <li>Harian : {employeeDailyAE.length}</li>
              )}
            </ul>
          </div>
          <div className='card d-flex flex-column flex-grow-1 bg-light shadow shadow-sm animate__animated animate__slideInUp' style={{ zIndex: '-1' }}>
            <div className='border-bottom border-2 p-2 text-center w-100'>
              <img src={logoBK} alt='logo-BK' className='logo-dashboard' />
            </div>
            <ul className='fs-5'>
              {!isValueEmpty(employeePermanentBK.length) && (
                <li>Tetap : {employeePermanentBK.length}</li>
              )}
              <li>Kontrak : {employeeContractBK.length}</li>
              {!isValueEmpty(employeeDailyBK.length) && (
                <li>Harian : {employeeDailyBK.length}</li>
              )}
            </ul>
          </div>
          <div className='card d-flex flex-column flex-grow-1 bg-light shadow shadow-sm animate__animated animate__slideInRight' style={{ zIndex: '-1' }}>
            <div className='border-bottom border-2 p-2 text-center w-100'>
              <img src={logoSN} alt='logo-SN' className='logo-dashboard' />
            </div>
            <ul className='fs-5'>
              {!isValueEmpty(employeePermanentSN.length) && (
                <li>Tetap : {employeePermanentSN.length}</li>
              )}
              <li>Kontrak : {employeeContractSN.length}</li>
              {!isValueEmpty(employeeDailySN.length) && (
                <li>Harian : {employeeDailySN.length}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default PkwtDashboard