import React from 'react'
import { Tooltip } from 'react-tooltip'

const Prestasi = ({dataPrestasiByNik}) => {

  return (
    <div className='d-flex flex-column' >
      {dataPrestasiByNik.map((items, i) => (
        <div key={i} className='d-flex align-items-center ms-2'>
          <i className="bi bi-award fs-1 text-warning"
            data-tooltip-id='prestasi-tooltip' data-tooltip-content='Prestasi'></i>
          <div className='ms-2 title-h1 text-success'>{items.prestasi}</div>
          <Tooltip id='prestasi-tooltip' />
        </div>
      ))}
    </div>
  )
}

export default Prestasi