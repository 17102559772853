import React from 'react'

const FilterEmployee = ({ selectedColumns, setSelectedColumns, formFilterRef }) => {

  const handelFilterColumns = (e) => {
    setSelectedColumns({
      ...selectedColumns, [e.target.name]: e.target.checked
    })
  }

  return (
    <div className='card border-black p-2' ref={formFilterRef}
      style={{ position: "fixed", top: "130px", right: "70px" }} >
      <div className='fs-4 text-decoration-underline' >Filter</div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='age'
          checked={selectedColumns.age} onChange={handelFilterColumns}
        />
        <label className='fs-5'>Umur</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='level'
          checked={selectedColumns.level} onChange={handelFilterColumns}
        />
        <label className='fs-5'>Level</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='workLocation'
          checked={selectedColumns.workLocation} onChange={handelFilterColumns}
        />
        <label className='fs-5'>Lokasi Kerja</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='workBond'
          checked={selectedColumns.workBond} onChange={handelFilterColumns} />
        <label className='fs-5'>Ikatan Kerja</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='generationAge'
          checked={selectedColumns.generationAge} onChange={handelFilterColumns} />
        <label className='fs-5'>Generasi</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='gender'
          checked={selectedColumns.gender} onChange={handelFilterColumns} />
        <label className='fs-5'>Jenis Kelamin</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='religion'
          checked={selectedColumns.religion} onChange={handelFilterColumns} />
        <label className='fs-5'>Agama</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='education'
          checked={selectedColumns.education} onChange={handelFilterColumns} />
        <label className='fs-5'>Pendidikan</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='weddingStatus'
          checked={selectedColumns.weddingStatus} onChange={handelFilterColumns} />
        <label className='fs-5'>Status Pernikahan</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='taxStatus'
          checked={selectedColumns.taxStatus} onChange={handelFilterColumns} />
        <label className='fs-5'>Status Pajak</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='noHp'
          checked={selectedColumns.noHp} onChange={handelFilterColumns} />
        <label className='fs-5'>No HP</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='noBpjsKes'
          checked={selectedColumns.noBpjsKes} onChange={handelFilterColumns} />
        <label className='fs-5'>No. BPJS Kesehatan</label>
      </div>
      <div className='d-flex gap-2'>
        <input type='checkbox' className='form-check-input fs-5' name='noBpjsKet'
          checked={selectedColumns.noBpjsKet} onChange={handelFilterColumns} />
        <label className='fs-5'>No. BPJS Ketenagakerjaan</label>
      </div>
    </div>
  )
}

export default FilterEmployee