import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const UpdateFoto = () => {

  const location = useLocation('')
  const dataDetailEmployees = location.state.dataDetailEmployees
  const dataEmployees = location.state.dataEmployees
  const navigate = useNavigate()

  const { nik, foto_karyawan } = dataDetailEmployees
  const [oldImage, setOldImage] = useState(`${process.env.REACT_APP_URL}/employees/${foto_karyawan}`)
  const [saveNewImage, setSaveNewImage] = useState(null)
  const [saveNameFile, setSaveNameFile] = useState('')

  const handleChangeImage = (e) => {
    const uploded = e.target.files[0]
    setOldImage(URL.createObjectURL(uploded))
    setSaveNewImage(uploded)
    setSaveNameFile(uploded.name)
  }

  const formImage = new FormData()
  formImage.append('image', saveNewImage)
  formImage.append('foto_karyawan', saveNameFile)

  const handleInputImage = () => {

    if (saveNameFile === '') {
      alert('Data Foto belum diubah')
    } else {
      axios.put(`${process.env.REACT_APP_URL}/employees/${nik}`, formImage)
        .then(() => {
          alert('Foto Berhasil Diubah')
          navigate(`/detailEmployee/${nik}`, { state: { dataEmployees: dataEmployees } })
        }).catch((err) => {
          alert('Gagal Update Foto')
          console.log(err)
        })
    }
  }

  return (
    <>
      <div className='bg-body-secondary rounded d-flex flex-column align-items-center'>
        <h3>Edit Foto Keryawan</h3>
        <div className='d-flex flex-column align-items-center rounded border border-1 border-black p-2 bg-light'>
          <img src={oldImage} alt='foto-karyawan' style={{ width: "200px", height: "220px" }} />
          <input type='file' onChange={handleChangeImage} className='m-2' accept="image/*" />
        </div>
        <button className='btn btn-sm fs-2 ' onClick={handleInputImage}>
          <i className='bi bi-check-lg text-dark fw-bold'></i>
        </button>
      </div>
    </>
  )
}

export default UpdateFoto