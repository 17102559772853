import React from 'react'
import NavigationBar from '../../Components/Utility/Navbar'
import { Accordion, AccordionItem } from 'react-bootstrap'
import InputVacation from '../../Vacation/Input'
import { useLocation } from 'react-router-dom'
import InputFamily from '../../Family/Input'
import InputWarningLetter from '../../WarningLetter/Input'
import InputPrestasi from '../../Prestasi/Input'
import InputTraining from '../../Training/Input'
import InputDrivingLicense from './../../DrivingLicense/Input'
import InputNotes from '../../Notes/Input'

const InputDataEmployee = ({ title }) => {

  const location = useLocation()
  const dataDetailEmployees = location.state.dataDetailEmployees
  const dataDrivingLicenseByNik = location.state.dataDrivingLicenseByNik
  const dataLicense = dataDrivingLicenseByNik.length > 0;
  const { nama_lengkap } = dataDetailEmployees

  return (
    <>
      <NavigationBar title={title} namaLengkap={nama_lengkap} />
      <Accordion className='container mt-1 mb-2 d-flex flex-column gap-2 animate__animated animate__fadeInDown'>
        <AccordionItem eventKey='0' >
          <Accordion.Header className='title-h1'>Input Cuti</Accordion.Header>
          <Accordion.Body>
            <InputVacation dataDetailEmployees={dataDetailEmployees} />
          </Accordion.Body>
        </AccordionItem>
        <AccordionItem eventKey='1' >
          <Accordion.Header className='title-h1'>Input Data keluarga</Accordion.Header>
          <Accordion.Body>
            <InputFamily dataDetailEmployees={dataDetailEmployees} />
          </Accordion.Body>
        </AccordionItem>
        <AccordionItem eventKey='2' >
          <Accordion.Header className='title-h1'>Input Surat Peringatan</Accordion.Header>
          <Accordion.Body>
            <InputWarningLetter dataDetailEmployees={dataDetailEmployees} />
          </Accordion.Body>
        </AccordionItem>
        <AccordionItem eventKey='3' >
          <Accordion.Header className='title-h1'>Input Prestasi Karyawan</Accordion.Header>
          <Accordion.Body>
            <InputPrestasi dataDetailEmployees={dataDetailEmployees} />
          </Accordion.Body>
        </AccordionItem>
        <AccordionItem eventKey='4' >
          <Accordion.Header className='title-h1'>Input Training Karyawan</Accordion.Header>
          <Accordion.Body>
            <InputTraining dataDetailEmployees={dataDetailEmployees} />
          </Accordion.Body>
        </AccordionItem>
        {!dataLicense && (
          <AccordionItem eventKey='5' >
            <Accordion.Header className='title-h1'>Input SimPol</Accordion.Header>
            <Accordion.Body>
              <InputDrivingLicense dataDetailEmployees={dataDetailEmployees} />
            </Accordion.Body>
          </AccordionItem>
        )}
        <AccordionItem eventKey='6' >
          <Accordion.Header className='title-h1'>Input Catatan</Accordion.Header>
          <Accordion.Body>
            <InputNotes dataDetailEmployees={dataDetailEmployees} />
          </Accordion.Body>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default InputDataEmployee