import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend)

const ChartLate = ({ dataPerformance }) => {

  const [filteredData, setFilteredData] = useState({})

  useEffect(() => {
    const dataHok = dataPerformance.filter(performance => {
      const year = new Date(performance.periode).getFullYear()
      return performance.category.toLowerCase() === 'tlt' && year === 2024
    })

    const totalPerUnit = dataHok.reduce((acc, performance) => {
      const unit = performance.unit.toLowerCase()
      const month = new Date(performance.periode).getMonth()

      if (!acc[unit]) acc[unit] = {}
      acc[unit][month] = (acc[unit][month] || 0) + Number(performance.value)

      return acc
    }, {})
    setFilteredData(totalPerUnit)

  }, [dataPerformance])

  const unitColors = {
    'aditama': { backgroundColor: 'rgba(13, 202, 240, 0.7)', borderColor: 'rgba(13, 202, 240, 1)' },
    'bara kumala': { backgroundColor: 'rgba(220, 53, 69, 0.7)', borderColor: 'rgba(220, 53, 69, 1)' },
    'sinet': { backgroundColor: 'rgba(25, 135, 84, 0.7)', borderColor: 'rgba(25, 135, 84, 1)' },
  }

  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des'],
    datasets: Object.entries(filteredData).map(([unit, monthlyTotals], index) => ({
      label: unit.toUpperCase(),
      data: Array.from({ length: 12 }, (_, month) => monthlyTotals[month] || 0),
      backgroundColor: unitColors[unit]?.backgroundColor || `rgba(${index * 30}, ${index * 50}, 200, 0.7)`,
      borderColor: unitColors[unit]?.borderColor || `rgba(${index * 30}, ${index * 50}, 200, 1)`,
      borderWidth: 1,
    }))
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Total Terlambat',
      },
    },
  }

  return (
    <div className='container w-75'>
      <Bar data={chartData} options={chartOptions} />
    </div>
  )
}

export default ChartLate