import React from 'react';
import { FloatingLabel } from 'react-bootstrap';
import logoBKG from '../../Assets/Logo/BKG.png';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Verify = () => {

  const location = useLocation();
  const email = location.state.email;
  const navigate = useNavigate()

  const handleSendCode = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formSendCode = Object.fromEntries(formData);

    axios.post(`${process.env.REACT_APP_URL}/users/verify-code`, {
      email: email,
      code: formSendCode.code
    })
      .then((res) => {
        if (res.data.verifyCode) {
          alert('Code terverifikasi silahkan reset ulang password anda')
          navigate('/reset-password', { state: { email: email } })
        }
      })
      .catch((err) => {
        console.log('err', err.message)
        if (err.response.status === 401) {
          alert('Code salah ! , coba lagi')
        }
      })
  }

  return (
    <div className='vh-100 d-flex justify-content-center align-items-center flex-column animate__animated animate__fadeInDown'>
      <img src={logoBKG} alt='logoBKG' className='p-2 w-25' />
      <form onSubmit={handleSendCode}
        className='d-flex flex-column gap-2 align-items-center card p-5 bg-light mt-2 shadow shadow-md'>
        <div className='fs-1 title-h1 text-decoration-underline'>Masukan Kode Verifikasi</div>
        <FloatingLabel label='Masukan code' className='w-100 mt-3'>
          <input
            type='text'
            name='code'
            minLength={6}
            placeholder='Masukan code'
            className='form-control'
            pattern='\d*'
            required
          />
        </FloatingLabel>
        <button type='submit' className='btn btn-dark mt-2 w-75'>Kirim Kode</button>
      </form>
    </div>
  )
}

export default Verify;
