import React from 'react'
import { FloatingLabel } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const UpdateID = () => {

  const location = useLocation()
  const dataDetailEmployees = location.state.dataDetailEmployees
  const dataEmployees = location.state.dataEmployees
  const navigate = useNavigate()

  const { nik, unit, status, jabatan, divisi, level, lokasi_kerja, ikatan_kerja, no_bpjs_kesehatan, no_bpjs_ketenagakerjaan } = dataDetailEmployees

  const handleFormUpdateID = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formUpdateProfileID = Object.fromEntries(formData)

    const username = localStorage.getItem('username')

    const reqDataProfileID = {
      nik: nik,
      new_unit: formUpdateProfileID.unitNew,
      new_status: formUpdateProfileID.statusNew,
      new_jabatan: formUpdateProfileID.jabatanNew,
      new_divisi: formUpdateProfileID.divisiNew,
      new_level: formUpdateProfileID.levelNew,
      new_lokasi_kerja: formUpdateProfileID.lokasiKerjaNew,
      new_ikatan_kerja: formUpdateProfileID.ikatanKerjaNew,
      new_no_bpjs_kesehatan: formUpdateProfileID.noBpjsKesehatanNew,
      new_no_bpjs_ketenagakerjaan: formUpdateProfileID.noBpjsKetenagakerjaanNew,
      new_updated_by: username
    }

    axios.put(`${process.env.REACT_APP_URL}/employees`, reqDataProfileID)
      .then((res) => {
        if (res.data) {
          alert('Berhasil mengubah Profile ID Karyawan')
          navigate(`/detailEmployee/${nik}`, { state: { dataEmployees: dataEmployees } })
        }
      }).catch((err) => {
        alert('Gagal mengubah Profile ID Karyawan Periksa Lagi !!!!')
        console.log('error', err)
      })
  }

  return (
    <>
      <div className='bg-body-secondary container bg-body-secondary mt-1 d-flex rounded shadow shadow-sm'>
        <form onSubmit={handleFormUpdateID} className='d-flex p-2 gap-5 w-100'>
          <div className='d-flex flex-column w-50'>
            <legend className='fs-5 fw-bold'>NIK</legend>
            <FloatingLabel label='NIK'>
              <input type='number' name='nikNew' defaultValue={nik} className='form-control ' disabled />
            </FloatingLabel>
            <legend className='fs-5 fw-bold'>Status</legend>
            <FloatingLabel label={status}>
              <select className="form-select" name='statusNew'>
                <option value={status}>Pilih Status yg ingin diubah</option>
                <option value="AKTIF">AKTIF</option>
                <option value="NON AKTIF">NON AKTIF</option>
              </select>
            </FloatingLabel>
            <legend className='fs-5 fw-bold'>Unit</legend>
            <FloatingLabel label={unit}>
              <select className="form-select" name='unitNew'>
                <option value={unit}>Pilih Unit yg ingin diubah</option>
                <option value="ADITAMA ENERGI">ADITAMA ENERGI</option>
                <option value="BARA KUMALA">BARA KUMALA</option>
                <option value="SINET">SINET</option>
              </select>
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Jabatan</label>
            <FloatingLabel label='Masukan Jabatan Baru' >
              <input type="text" defaultValue={jabatan} className="form-control" name='jabatanNew' />
            </FloatingLabel>
            <label className='fs-5 fw-bold'>Divisi</label>
            <FloatingLabel label={divisi}>
              <select className="form-select" name='divisiNew'>
                <option value={divisi}>Pilih Divisi Karyawan yg ingin diubah</option>
                <option value="Produksi">Produksi</option>
                <option value="Engineering">Engineering</option>
                <option value="Perencanaan">Perencanaan</option>
                <option value="Umum">Umum</option>
                <option value="Explorasi">Explorasi</option>
                <option value="Keuangan">Keuangan</option>
                <option value="Logistik">Logistik</option>
                <option value="Rental Heavy Equipment">Rental Heavy Equipment</option>
                <option value="Coal Processing And Shipping">Coal Processing And Shipping</option>
                <option value="HSSE, Tambang, Compliance">HSSE, Tambang, Compliance</option>
                <option value="Peralatan">Peralatan</option>
                <option value="Workshop">Workshop</option>
                <option value="Security">Security</option>
              </select>
            </FloatingLabel>
          </div>
          <div className='d-flex flex-column w-50'>
            <label className='fs-5 fw-bold'>Level</label>
            <FloatingLabel label={level} >
              <select className="form-select" name='levelNew'>
                <option value={level}>Pilih Level Karyawan yg ingin diubah</option>
                <option value="NON STAF & PELAKSANA">NON STAF & PELAKSANA</option>
                <option value="SUPERVISI & FOREMAN">SUPERVISI & FOREMAN</option>
                <option value="MANAGER & SUPER INTENDENT">MANAGER & SUPER INTENDENT</option>
              </select>
            </FloatingLabel>
            <legend className='fs-5 fw-bold'>Lokasi Kerja</legend>
            <FloatingLabel label={lokasi_kerja}>
              <select className="form-select" name='lokasiKerjaNew'>
                <option value={lokasi_kerja}>Pilih Lokasi kerja yg baru</option>
                <option value="SMD">SMD</option>
                <option value="SITE">SITE</option>
              </select>
            </FloatingLabel>
            <legend className='fs-5 fw-bold'>Ikatan Kerja</legend>
            <FloatingLabel label={ikatan_kerja}>
              <select className="form-select" name='ikatanKerjaNew'>
                <option value={ikatan_kerja}>Pilih Ikatan kerja yg baru</option>
                <option value="TETAP">Tetap</option>
                <option value="KONTRAK">Kontrak</option>
                <option value="HARIAN">Harian</option>
              </select>
            </FloatingLabel>
            <label className='fs-5 fw-bold'>No BPJS Kesehatan</label>
            <FloatingLabel label='Masukan No BPJS Kesehatan yg baru'>
              <input type='number' className='form-control' defaultValue={no_bpjs_kesehatan} name='noBpjsKesehatanNew' />
            </FloatingLabel>
            <label className='fs-5 fw-bold'>No BPJS Ketenagkerjaan</label>
            <FloatingLabel label='Masukan No BPJS Ketenagkerjaan yg baru'>
              <input type='text' className='form-control' defaultValue={no_bpjs_ketenagakerjaan} name='noBpjsKetenagakerjaanNew' />
            </FloatingLabel>
          </div>
          <button type='submit' className='btn btn-lg fs-2 h-25'>
            <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
          </button>
        </form>
      </div>
    </>
  )
}

export default UpdateID