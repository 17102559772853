import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import UpdateKpi from './KPI/Update'
import UpdateHOK from './Absensi/HOK/Update'
import UpdateAbsent from './Absensi/Absent/Update'
import UpdateJOK from './Absensi/JOK/Update'
import UpdateLate from './Absensi/Late/Update'
import UpdateDailyReport from './DR/Update'
import UpdatePermission from './Absensi/Permission/Update'
import UpdateSick from './Absensi/Sick/Update'
import UpdateGoHome from './Absensi/Go Home/Update'
import UpdateEvaluation from './Evaluation/Update'

const UpdatePerformance = ({ dataDetailEmployees }) => {
  const { nik } = dataDetailEmployees
  const navigate = useNavigate()
  const location = useLocation()
  const dataEmployees = location.state.dataEmployees

  const [dataPerformanceByNik, setDataPerformanceByNik] = useState([])

  const filterDataPerformanceByNik = (category, periode) => {
    return dataPerformanceByNik.filter(items => {
      const matchCategory = category ? items.category.toLowerCase() === category.toLowerCase() : true
      const matchPeriode = periode ? items.periode.toLowerCase() === periode.toLowerCase() : true
      return matchCategory && matchPeriode
    })
  }

  const getMonthlyData = (category, dates) => {
    return dates.map(date => {
      const data = filterDataPerformanceByNik(category, date)
      return data.map(item => ({
        id: item.id_performance,
        category: item.category,
        value: item.value
      }))
    })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/performance/${nik}`)
      .then((res) => setDataPerformanceByNik(res.data.dataPerformanceByNik))
      .catch((err) => console.log('error', err))
  }, [nik])

  const handleFormValue = (e, month, formRef) => {
    e.preventDefault()

    if (formRef.current) {
      const formData = new FormData(formRef.current)
      const formValue = Object.fromEntries(formData.entries())

      const selectedId = formValue[`id${month}`]
      const selectedValue = formValue[`value${month}`]

      axios.put(`${process.env.REACT_APP_URL}/performance`, {
        id_performance: selectedId,
        new_value: selectedValue
      })
        .then((res) => {
          if (res.data.performance) {
            alert(`Berhasil mengubah ${formValue.category}`)
            navigate(`/detailEmployee/${nik}`, { state: { dataEmployees: dataEmployees } })
          }
        }).catch((err) => {
          console.log(err)
          alert(`${formValue.category} undefined`)
        })
    } else {
      console.log('form undefined')
    }
  }

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        <UpdateHOK getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
        <UpdateJOK getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
        <UpdateKpi getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
        <UpdateDailyReport getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
      </div>
      <div className='d-flex'>
        <UpdateLate getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
        <UpdateAbsent getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
        <UpdatePermission getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
        <UpdateSick getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
        <UpdateGoHome getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
      </div>
      <UpdateEvaluation getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
    </div>
  )
}

export default UpdatePerformance
