import React from 'react'
import { dateFormated } from '../Components/Utility'
import { Tooltip } from 'react-tooltip'
import { deleteDrivingLicense } from './Delete'

const DrivingLicense = ({ dataDrivingLicenseByNik, isAdmin }) => {

  const dataLicensi = [
    { value: 'no_license', label: ' No SIM' },
    { value: 'type', label: 'Jenis SIM' },
    { value: 'date_expired', label: 'Berlaku s/d' },
    { label: 'Sisa' },
    { value: 'region', label: 'Wilayah' }
  ]

  const getRemainingTime = (date) => {
    const now = new Date()
    const targetDate = new Date(date)
    const totalDayRemaining = Math.floor((targetDate - now) / (1000 * 60 * 60 * 24))

    if (totalDayRemaining < 0) return 'Sim sudah tidak berlaku'

    const years = Math.floor(totalDayRemaining / 365)
    const months = Math.floor((totalDayRemaining % 365) / 30)
    const days = totalDayRemaining % 30

    let remainingDays;
    if (years > 0) {
      remainingDays = `${years} tahun, ${months} bulan, ${days} hari`;
    } else if (months > 0) {
      remainingDays = `${months} bulan, ${days} hari`;
    } else {
      remainingDays = `${days} hari`;
    }

    return remainingDays
  }

  return (
    <div className='container animate__animated animate__fadeInDown card mt-2'>
      <div className='title-h1 text-center fs-1 fw-bold'>Sim Polisi</div>
      {dataDrivingLicenseByNik.map((items, i) => (
        <div key={i} className='d-flex justify-content-between border p-2 mb-2 rounded border-dark bg-body-secondary'>
          <div className='d-flex flex-column ms-1 justify-content-center mb-1 rounded  w-50 card shadow'>
            {dataLicensi.map((item, j) => (
              <div key={j} className='d-flex gap-2 fs-4'>
                <div className='w-25 text-end ms-4'>{item.label} : </div>
                {item.value === 'date_expired' ? (
                  <div className='fw-bold'>{dateFormated(items[item.value])}</div>
                ) : item.label === 'Sisa' ? (
                  <div className='fw-bold'>{getRemainingTime(items.date_expired)}</div>
                ) : (
                  <div className='fw-bold'>{items[item.value]}</div>
                )}
              </div>
            ))}
          </div>
          <img src={`${process.env.REACT_APP_URL}/license/${items.filename}`} alt='foto sim karyawan'
            className='w-50 ms-3 border card rounded shadow p-2 mb-2 animate__animated animate__fadeInRight'
          />
          {isAdmin && (
            <i className='bi bi-trash text-danger ms-1 fs-5  h-25 p-1' style={{ cursor: 'pointer' }}
              data-tooltip-id='delete-license-tooltip' data-tooltip-content='hapus SimPol'
              onClick={() => deleteDrivingLicense(items.id_license)}
            ></i>
          )}
          <Tooltip id='delete-license-tooltip' place='bottom' />
        </div>
      ))}
    </div>
  )
}

export default DrivingLicense