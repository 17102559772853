import axios from 'axios'
import React, { useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const UpdateTraining = ({ dataDetailEmployees }) => {

  const location = useLocation()
  const dataTrainingByNik = location.state.dataTrainingByNik
  const formRefs = useRef([])
  const admin = localStorage.getItem('username')
  const { nik, nama_lengkap } = dataDetailEmployees
  const navigate = useNavigate()

  const labelTraining = [
    { label: 'id training', name: 'id_training' },
    { label: 'Pelatihan', w: 'w-50', name: 'training' },
    { label: 'Tempat', w: 'w-50', name: 'place' },
    { label: 'Penyelenggara', w: 'w-50', name: 'organizer' },
    { label: 'Tanggal Mulai', type: 'date', name: 'date_start' },
    { label: 'Tanggal Selesai', type: 'date', name: 'date_end' },
    { label: 'Keterangan', w: 'w-50', name: 'info' }
  ]

  const handleUpdateTraining = (e, id_training) => {
    e.preventDefault()

    const formRef = formRefs.current[id_training]
    if (formRef) {
      const formData = new FormData(formRef)
      const formUpdateTraining = Object.fromEntries(formData.entries())

      const reqUpdateTraining = {
        id_training: id_training,
        new_organizer: formUpdateTraining.organizer,
        new_place: formUpdateTraining.place,
        new_date_start: formUpdateTraining.date_start,
        new_date_end: formUpdateTraining.date_end,
        new_training: formUpdateTraining.training,
        new_info: formUpdateTraining.info,
        new_updated_by: admin
      }

      axios.put(`${process.env.REACT_APP_URL}/training`, reqUpdateTraining)
        .then((res) => {
          if (res.data.updateDataTraining) {
            alert(`Berhasil Update data Pelatihan ${nama_lengkap}`)
            navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
          }
        })
        .catch((err) => console.log('err', err))
    }
  }

  return (
    <div className='container bg-body-secondary p-3 rounded shadow-sm'>
      {dataTrainingByNik.map((items, i) => (
        <form key={i} className='card p-2 mt-2 d-flex gap-2'
          ref={et => formRefs.current[items.id_training] = et}
          onSubmit={(e) => handleUpdateTraining(e, items.id_training)}
        >
          {labelTraining.map((item, j) => (
            <div key={j} className='d-flex'>
              <label className='fs-5 fw-bold text-end w-25'>{item.label} : </label>
              <input type={item.type} className={`${item.w} w-25 input-underlined ms-2`}
                defaultValue={items[item.name]} name={item.name}
              />
            </div>
          ))}
          <button type='submit' className='btn btn-primary w-75 ms-5 mt-3 mb-2'>Update</button>
        </form>
      ))}
    </div>
  )
}

export default UpdateTraining