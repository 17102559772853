import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const UpdateVacation = ({ dataDetailEmployees }) => {

  const { nik } = dataDetailEmployees
  const [dataVacationByNik, setDataVacationByNik] = useState([])
  const admin = localStorage.getItem('username')
  const formRefs = useRef([])
  const navigate = useNavigate()

  const nameUnitForAlert = (category) => {
    const categoryMap = {
      cut: 'Cuti Tahunan',
      cup: 'Cuti Periodik',
      cum: 'Cuti Melahirkan',
      cub: 'Cuti Bersama',
      off: 'OFF'
    }
    return categoryMap[category]
  }

  const dataLabel = [
    { label: 'ID Cuti', name: 'idLetter', value: 'id_letter' },
    { label: 'No Surat', name: 'noLetter', value: 'no_letter' },
    { label: 'Kategori', name: 'category', value: 'category' },
    { label: 'NIK', value: 'nik', name: 'nik' },
    { label: 'Nama', value: 'fullname', name: 'fullname' },
    { label: 'Unit', value: 'unit', name: 'unit' },
    { label: 'Tgl Cuti', name: 'dateVacation', value: 'date_vacation' },
    { label: 'Tgl Kembali Kerja', name: 'dateWork', value: 'date_work' },
    { label: 'Total Cuti', name: 'total', value: 'total' },
    { label: 'Keterangan', name: 'info', value: 'info' }
  ]

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/vacation/${nik}`)
      .then((res) => setDataVacationByNik(res.data.dataVacationByNik))
      .catch((err) => console.log('error', err))
  }, [nik])

  const handleFormUpdateVacation = (e, idLetter) => {
    e.preventDefault()

    const formRef = formRefs.current[idLetter]
    if (formRef) {
      const formData = new FormData(formRef)
      const formUpdateVacation = Object.fromEntries(formData.entries())

      const reqDataUpdateVacation = {
        id_letter: idLetter,
        new_no_letter: formUpdateVacation.noLetter,
        new_category: formUpdateVacation.category,
        new_fullname: formUpdateVacation.fullname,
        new_unit: formUpdateVacation.unit,
        new_date_vacation: formUpdateVacation.dateVacation,
        new_date_work: formUpdateVacation.dateWork,
        new_total: formUpdateVacation.total,
        new_info: formUpdateVacation.info,
        new_updated_by: admin
      }

      axios.put(`${process.env.REACT_APP_URL}/vacation`, reqDataUpdateVacation)
        .then((res) => {
          if (res.data.updateDataVacation) {
            alert(`Behasil Update ${nameUnitForAlert(formUpdateVacation.category)} ${formUpdateVacation.fullname}`)
            navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
          }
        })
        .catch((err) => console.log('eror', err))

    } else {
      console.log('form undefined')
    }
  }

  return (
    <div className='container bg-body-secondary rounded'>
      {dataVacationByNik.map((item, i) => (
        <form
          ref={el => formRefs.current[item.id_letter] = el}
          key={i}
          className='d-flex flex-column gap-2 p-2'
          onSubmit={(e) => handleFormUpdateVacation(e, item.id_letter)}
        >
          <div className='d-flex flex-column p-4 card gap-1'>
            {dataLabel.map((items, j) => {
              const inputType = items.label === 'Tgl Cuti' || items.label === 'Tgl Kembali Kerja' ? 'date' : items.label === 'Total Cuti' || items.label === 'NIK' ? 'number' : 'text'
              const inputWidth = inputType === 'date' || inputType === 'number' ? 'w-25' : 'w-50'
              return (
                <div key={j} className='d-flex w-75 gap-3'>
                  {items.label !== 'ID Cuti' && (
                    <>
                      <label className='fs-5 fw-bold w-25 text-end'>{items.label} :</label>
                      <input
                        type={inputType}
                        className={`input-underlined ${inputWidth} `}
                        defaultValue={item[items.value]}
                        name={items.name}
                      />
                    </>
                  )}
                </div>
              )
            })}
            <button type='submit' className='btn btn-info mt-2 w-50 ms-5'>Update</button>
          </div>
        </form>
      ))}
    </div>
  )
}

export default UpdateVacation
