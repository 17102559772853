import React from 'react'

const Late = ({ dataEmployeeFiltered, dataPerformance }) => {

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des']
  const dataLabel = [
    'No.', 'NIK', 'Nama', ...months, 'Total '
  ]

  const groupPerformanceByEmployeeAndMonth = (nik, month) => {
    const performanceData = dataPerformance.filter(performance => performance.nik === nik && new Date(performance.periode).getMonth() === month && performance.category.toLowerCase() === 'tlt')
    return performanceData.reduce((total, performance) => total + Number(performance.value), 0)
  }

  return (
    <table className='table table-bordered border-dark'>
      <thead className='table-info table-bordered border-dark sticky-header'>
        <tr>
          {dataLabel.map((label, i) => (
            <th key={i} className='text-center fw-bold'>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataEmployeeFiltered.map((items, j) => {
          const monthlyPerformance = months.map((_, monthIndex) =>
            groupPerformanceByEmployeeAndMonth(items.nik, monthIndex)
          )
          const totalPerformance = monthlyPerformance.reduce((total, monthTotal) => total + monthTotal, 0)
          return (
            <tr className='text-center' key={j}>
              <td>{j + 1}.</td>
              <td>{items.nik}</td>
              <td className='text-start'>{items.nama_lengkap}</td>
              {monthlyPerformance.map((total, k) => (
                <td key={k}>{total === 0 ? '-' : total}</td>
              ))}
              <td className='fw-bold'>{totalPerformance !== 0 ? `${totalPerformance} hari` : '-'}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Late