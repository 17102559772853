import React from "react"
import { Accordion, AccordionItem } from 'react-bootstrap'
import { logout } from '../../Components/Utility'
import { Tooltip } from "react-tooltip"
import UpdateEmail from "../Email/Update"
import UpdatePassword from "./Update"

const Profile = ({ formProfileRef, username }) => {

  return (
    <div className="card p-4 bg-body-secondary position-absolute shadow" style={{
      top: "30px", right: "32px", width: "350px"
    }}>
      <h2 className="border-bottom border-2 border-black mb-3 title-h1">{username}</h2>
      <Accordion ref={formProfileRef}>
        <AccordionItem eventKey="0" className="bg-body-secondary shadow">
          <Accordion.Header>
            <legend className="fs-5">Ubah Password</legend>
          </Accordion.Header>
          <Accordion.Body>
            <UpdatePassword username={username} />
          </Accordion.Body>
        </AccordionItem>
        <AccordionItem eventKey="1" className="bg-body-secondary shadow">
          <Accordion.Header>
            <legend className="fs-5">Ubah Email</legend>
          </Accordion.Header>
          <Accordion.Body>
            <UpdateEmail username={username} />
          </Accordion.Body>
        </AccordionItem>
        <i className="bi bi-box-arrow-right fs-2 text-danger btn btn-sm mt-2 w-25"
          data-tooltip-id="logout-tooltip" data-tooltip-content="logout"
          onClick={() => logout()}>
        </i>
        <Tooltip id="logout-tooltip" place="bottom" />
      </Accordion>
    </div >
  )
}

export default Profile
