import React from 'react'
import { dateFormated } from './../Components/Utility/index';

const Family = ({ dataDetailEmployees, dataFamilyByNik }) => {

  const { status_pajak } = dataDetailEmployees

  const labelFamily = [
    { label: 'No.' },
    { label: 'Nama Lengkap', type: 'text', name: 'fullname', widht: 'w-50' },
    { label: 'Hubungan', type: 'select', options: ['Anak', 'Istri', 'Suami'], name: 'relation' },
    { label: 'Jenis Kelamin', type: 'select', options: ['Perempuan', 'Laki-laki'], name: 'gender' },
    { label: 'Tempat Lahir', type: 'text', name: 'placeBirth' },
    { label: 'Tanggal Lahir', type: 'date', name: 'dateBirth' }
  ]

  return (
    <div className='container card animate__animated animate__fadeInDown'>
      <div className='title-h1 fs-1 text-center'>Data Keluarga ({status_pajak})</div>
      <table className='table table-bordered table-hover border-dark'>
        <thead className='table-info border-dark'>
          <tr className='text-center fw-bold fs-5 table-active'>
            {labelFamily.map((families, i) => (
              <th key={i}>{families.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className='table-info border-dark'>
          {dataFamilyByNik.map((family, j) => (
            <tr key={j} className='text-center'>
              <td className='fw-bold'>{j + 1}.</td>
              <td>{family.fullname}</td>
              <td>{family.relation}</td>
              <td>{family.gender}</td>
              <td>{family.place_birth}</td>
              <td>{dateFormated(family.date_birth)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Family