import axios from 'axios';
import React from 'react'
import '../../Global.css';

const InputReminder = ({ formInputReminderRef, admin }) => {

  const formInputReminder = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formReminder = Object.fromEntries(formData);

    const data = {
      reminder: formReminder.reminder,
      date: formReminder.date,
      notes: formReminder.notes,
      updated_by: admin
    }

    axios.post(`${process.env.REACT_APP_URL}/reminder`, data)
      .then((res) => {
        if (res.data.createReminder) {
          alert('Reminder ditambahkan')
          window.location.reload();
        }
      })
      .catch((err) => console.log('err', err.message))
  }

  return (
    <div className='card bg-body-secondary p-2 shadow w-50'
      style={{
        position: 'absolute',
        right: '80px',
        top: '20px',
        zIndex: 1000,
        width: '400px',
      }}>
      <h1 className='text-center text-decoration-underline'>Input Reminder</h1>
      <form onSubmit={formInputReminder}
        ref={formInputReminderRef}
        className='d-flex flex-column p-2 fw-bold'>
        <label className='fs-4'>Agenda</label>
        <input
          className='form-control'
          placeholder='masukan reminder'
          name='reminder' />
        <label className='fs-4 mt-2'>Tanggal</label>
        <input
          type='date'
          className='form-control w-25'
          name='date' />
        <label className='fs-4 mt-2'>Catatan</label>
        <textarea
          className='form-control'
          placeholder='masukan catatan'
          name='notes'
          style={{ height: "90px" }} />
        <button type='submit' className='btn btn-dark mt-4 w-75'>Save</button>
      </form>
    </div>
  )
}

export default InputReminder;