import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const UpdateFamily = ({ dataDetailEmployees }) => {

  const { nik, nama_lengkap } = dataDetailEmployees
  const formRefs = useRef([])
  const [dataFamilyByNik, setDataFamilyByNik] = useState([])
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/family/${nik}`)
      .then((res) => setDataFamilyByNik(res.data.dataFamilyByNik))
      .catch((err) => console.log('err', err))
  }, [nik])

  const labelFamily = [
    { label: 'Id Family', value: 'id_family', type: 'text', name: 'idFamily' },
    { label: 'Nama Lengkap', value: 'fullname', type: 'text', name: 'fullname' },
    { label: 'Hubungan', value: 'relation', type: 'select', options: ['Anak', 'Istri', 'Suami'], name: 'relation' },
    { label: 'Jenis Kelamin', value: 'gender', type: 'select', options: ['Perempuan', 'Laki-laki'], name: 'gender' },
    { label: 'Tempat Lahir', value: 'place_birth', type: 'text', name: 'placeBirth' },
    { label: 'Tanggal Lahir', value: 'date_birth', type: 'date', name: 'dateBirth' }
  ]

  const handleFormUpdateFamily = (e, idFamily) => {
    e.preventDefault()

    const formRef = formRefs.current[idFamily]
    if (formRef) {
      const formData = new FormData(formRef)
      const formUpdateFamily = Object.fromEntries(formData.entries())

      const reqDataFamily = {
        id_family: idFamily,
        new_fullname: formUpdateFamily.fullname,
        new_relation: formUpdateFamily.relation,
        new_gender: formUpdateFamily.gender,
        new_place_birth: formUpdateFamily.placeBirth,
        new_date_birth: formUpdateFamily.dateBirth,
        new_updated_by: admin
      }

      axios.put(`${process.env.REACT_APP_URL}/family`, reqDataFamily)
        .then((res) => {
          if (res.data.updateDataFamily) {
            alert(`Berhasil update Data Keluarga ${nama_lengkap}`)
            navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
          }
        })
        .catch((err) => console.log('err', err))
    }
  }

  return (
    <div className=' mt-1 rounded'>
      {dataFamilyByNik.map((item, i) => (
        <form key={i}
          ref={ef => formRefs.current[item.id_family] = ef}
          className='p-3 border d-flex flex-column rounded m-2 bg-body-secondary'
          onSubmit={(e) => handleFormUpdateFamily(e, item.id_family)}
        >
          {labelFamily.map((items, j) => (
            <div key={j} className='d-flex gap-2 p-1'>
              {items.label !== 'Id Family' && (
                <>
                  <label className='fs-5 w-25 text-end fw-bold'>{items.label} : </label>
                  {items.type === 'select' ? (
                    <select className='form-control w-50' name={items.name} defaultValue={item[items.value]}>
                      {items.options.map((option, k) => (
                        <option key={k} value={item[option]} >{option}</option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={items.type}
                      className='form-control w-50'
                      defaultValue={item[items.value]}
                      name={items.name}
                    />
                  )}
                </>
              )}
            </div>
          ))}
          <button type='submit' className='btn btn-md btn-primary w-75 ms-5 text-dark mt-4'>Update</button>
        </form>
      ))}
    </div>
  )
}

export default UpdateFamily