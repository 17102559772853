import React from 'react'
import { months } from '../../Components/Utility'

const DailyReport = ({ filterDataPerformanceByNik }) => {

  const dataDrByMonth = months.map((date) => {
    const data = filterDataPerformanceByNik('dr', date)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const validValues = dataDrByMonth.filter(value => value !== '-').map(value => parseFloat(value))
  const total = validValues.reduce((acc, value) => acc + value, 0)
  const average = (validValues.length > 0 ? (total / validValues.length) : 0).toFixed(2)

  return (
    <tbody>
      <tr className='text-center'>
        <td className='fw-bold'>DR</td>
        {dataDrByMonth.map((value, i) => (
          <td key={i}>{value !== '-' ? `${value}` : value}</td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{average > 0.00 ? `${average} %` : '-'}</td>
      </tr>
    </tbody >
  )
}

export default DailyReport