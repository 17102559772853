import axios from 'axios'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const InputWarningLetter = () => {

  const location = useLocation()
  const dataDetailEmployees = location.state.dataDetailEmployees
  const { nik, nama_lengkap, unit } = dataDetailEmployees
  const admin = localStorage.getItem('username')
  const navigate = useNavigate()

  const labelWarningLetter = [
    { label: 'No Surat', type: 'text', w: 'w-50', name: 'noLetter' },
    { label: 'Tahun', type: 'number', name: 'year' },
    { label: 'Jenis SP', type: 'select', option: ['SP 1', 'SP 2', 'SP 3'], name: 'type' },
    { label: 'Tanggal Surat', type: 'date', name: 'dateLetter' },
    { label: 'Tanggal Berlaku', type: 'date', name: 'dateStart' },
    { label: 'Tanggal Akhir SP', type: 'date', name: 'dateEnd' },
    { label: 'Keterangan SP', type: 'text-area', name: 'info' }
  ]

  const handleFormWarningLetter = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formWarningLetter = Object.fromEntries(formData)

    const reqFormWarningLetter = {
      no_letter: formWarningLetter.noLetter,
      fullname: nama_lengkap,
      nik: nik,
      unit: unit,
      year: Number(formWarningLetter.year),
      type: formWarningLetter.type,
      info: formWarningLetter.info,
      date_letter: formWarningLetter.dateLetter,
      date_start: formWarningLetter.dateStart,
      date_end: formWarningLetter.dateEnd,
      updated_by: admin
    }

    axios.post(`${process.env.REACT_APP_URL}/warningletters`, reqFormWarningLetter)
      .then((res) => {
        if (res.data.dataWarningLetter) {
          alert(`Berhasil Menambahkan SP ${nama_lengkap}`)
          navigate(`/detailEmployee/${nik}`, { state: { dataDetailEmployees: dataDetailEmployees } })
        }
      })
      .catch((err) => console.log('err', err))
  }

  return (
    <div className='container bg-danger shadow-sm rounded p-2 animate__animated animate__fadeInDown'>
      <form onSubmit={handleFormWarningLetter}>
        {labelWarningLetter.map((items, i) => (
          <div key={i} className='d-flex gap-2 p-2'>
            <label className='w-25 text-end fw-bold fs-5'>{items.label} : </label>
            {items.type === 'select' ? (
              <select className='form-control w-25' name={items.name} required>
                {items.option.map((option, j) =>
                  <option
                    key={j}
                    value={option}
                  >{option}</option>
                )}
              </select>
            ) : items.type === 'text-area' ? (
              <textarea
                className='w-75 form-control'
                style={{ height: '100px' }}
                name={items.name}
                placeholder='Masukan Detail SP'
                required
              ></textarea>
            ) : (
              <input
                type={items.type}
                className={`form-control w-25 ${items.w}`}
                name={items.name}
                placeholder={`Masukan ${items.label}`}
                required />
            )}
          </div>
        ))}
        <button type='submit' className='btn btn-md btn-dark ms-5 w-75 mt-4'>Submit</button>
      </form>
    </div>
  )
}

export default InputWarningLetter