import axios from 'axios';
import React from 'react'
import '../../Global.css';

const UpdateReminder = ({ formUpdateReminderRef, selectedReminder, admin }) => {

  const { id_reminder, reminder, date, notes } = selectedReminder;

  const formUpdateReminder = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formReminder = Object.fromEntries(formData);

    const data = {
      id_reminder,
      new_reminder: formReminder.reminder,
      new_date: formReminder.date,
      new_notes: formReminder.notes,
      new_updated_by: admin
    }

    axios.put(`${process.env.REACT_APP_URL}/reminder`, data)
      .then((res) => {
        if (res.data.updateReminder) {
          alert('Reminder sudah diubah !')
          window.location.reload();
        }
      })
      .catch((err) => console.log('err', err.message))
  }

  return (
    <div className='card bg-body-secondary p-2 shadow w-50'
      style={{
        position: 'absolute',
        left: '250px',
        top: '50px',
        zIndex: 1000,
        width: '400px',
      }}>
      <h1 className='text-center text-decoration-underline'>Update Reminder</h1>
      <form onSubmit={formUpdateReminder}
        ref={formUpdateReminderRef}
        className='d-flex flex-column p-2 fw-bold'>
        <label className='fs-4'>Agenda</label>
        <input
          className='form-control'
          placeholder='masukan reminder yg ingin diubah'
          name='reminder'
          defaultValue={reminder} />
        <label className='fs-4 mt-2'>Tanggal</label>
        <input
          type='date'
          className='form-control w-25'
          name='date'
          defaultValue={date} />
        <label className='fs-4 mt-2'>Catatan</label>
        <textarea
          className='form-control'
          placeholder='masukan catatan yg ingin diubah'
          name='notes'
          defaultValue={notes}
          style={{ height: "90px" }} />
        <button type='submit' className='btn btn-primary text-dark fw-bold mt-4 w-75'>Update</button>
      </form>
    </div>
  )
}

export default UpdateReminder;